import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get_user_profile_details, update_user_profile } from "../../../actions/action-userProfiles";
import CommonSnackBar from "../../common/CommonSnackBar";
import { history } from '../../../reducers';
import Sidebar from '../../layout/Sidebar';
import configureStore from '../../../store/configureStore';
import FormErrors from "../../FormErrors";
import Input from '@material-ui/core/Input';
import FormButton from '../../common/FormButton';
import Loader from '../../common/Loader';
import PolicyCard from '../../common/PolicyCard';
import { showSnackbar, hideSnackbar } from '../../../actions/action-snackbar';

const store = configureStore();


class EditUser extends Component {

    constructor() {
        super();
        this.state = {
            userDetails: {},
            policies: []

        };
    }

    componentDidMount() {
        if (!this.props.auth.isAuthenticated) {
            const path = window.location.pathname;
            history.push(`/login?redirect=${path}`);
            return
        }
        this.fetchDetails();
    }

    // componentWillUnmount(){
    //     store.dispatch(hideSnackbar());
    // }

    async fetchDetails() {
        const path = window.location.pathname.split('/');
        const username = path[path.length - 1];
        const { get_user_profile_details } = this.props;
        await get_user_profile_details(username);
        const { userProfileDetails } = store.getState().userProfile;
        this.setState({
            userDetails: userProfileDetails,
            policies: userProfileDetails.data.policies
        });
    }

    clearErrorState = () => {
        this.setState({
            errors: {}
        });
    }


    handleSubmit = async event => {
        const path = window.location.pathname.split('/');
        const username = path[path.length - 1];
        const { update_user_profile } = this.props;
        let userDetails = this.state.userDetails;
        userDetails['policies'] = this.state.policies;
        await update_user_profile(this.state.userDetails, username);
        const { activeOuid } = store.getState().permission;
        this.props.history.push(`/${activeOuid}/users`);
    }

    onInputChange = event => {
        const newUserDetails = this.state.userDetails;
        newUserDetails.data[event.target.id] = event.target.value;
        this.setState({
            userDetails: newUserDetails
        });
    }

    deletePolicy = async index => {
        console.log("deletePolicy : ", index);
        let policies = this.state.policies;
        policies.splice(index, 1);
        this.setState({
            policies: policies
        });
    }

    render() {
        const { showGlobalLoader } = store.getState().userProfile;
        const { permissions } = store.getState().permission;
        return (
            <div className="page">
                <Sidebar
                    auth={this.props.auth}
                    permissions={permissions}
                />
                <section className="section">
                    {showGlobalLoader ?
                        <Loader />
                        :
                        <div className="container">
                            {this.state.userDetails.data && (
                                <div className="formContainer">
                                    <FormErrors formerrors={this.state.errors} />
                                    <form>
                                        <h1 className="formName">Edit User Details</h1>
                                        <div className="form-item-wrapper">
                                            <Input
                                                className="input"
                                                id="name"
                                                placeholder="Full Name"
                                                value={this.state.userDetails.data.name}
                                                onChange={this.onInputChange}
                                            />
                                        </div>
                                        <div className="form-item-wrapper">
                                            <Input
                                                className="input"
                                                id="username"
                                                placeholder="User Name"
                                                value={this.state.userDetails.data.username}
                                                onChange={this.onInputChange}
                                                readOnly
                                            />
                                        </div>
                                        <div className="form-item-wrapper">
                                            <Input
                                                className="input"
                                                placeholder="Email"
                                                id="email"
                                                value={this.state.userDetails.data.email}
                                                onChange={this.onInputChange}
                                                readOnly
                                            />
                                        </div>
                                        <div className="form-item-wrapper">
                                            <Input
                                                className="input"
                                                id="phone_number"
                                                placeholder="Phone Number"
                                                value={this.state.userDetails.data.phone_number}
                                                onChange={this.onInputChange}
                                                readOnly
                                            />
                                        </div>
                                        {this.state.policies && (
                                            <div>
                                                {this.state.policies.map((item, index) => (
                                                    <PolicyCard key={index}
                                                        policy={item}
                                                        index={index}
                                                        deletePolicy={(index) => this.deletePolicy(index)}
                                                        editable
                                                    />
                                                ))
                                                }
                                            </div>
                                        )}

                                    </form>
                                    <div className="field">
                                        <p className="control">
                                            <FormButton
                                                onClick={() => this.handleSubmit()}
                                                text="Update"
                                            />
                                        </p>
                                    </div>
                                    <CommonSnackBar />
                                </div>
                            )}

                        </div>
                    }
                </section>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userProfile: state.userProfile,
    permission: state.permission,
});


const mapDispatchToProps = (dispatch) => ({
    get_user_profile_details: (username) => dispatch(get_user_profile_details(username)),
    update_user_profile: (payload, username) => dispatch(update_user_profile(payload, username)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
