import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Tooltip from '@material-ui/core/Tooltip';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Orgunit } from '@material-ui/icons';
import { history } from '../../reducers';
import Button from '@material-ui/core/Button';
import { RedirectFunc } from "../common/CommonFunctions";
import { setActiveOiudConfigs } from "../../actions/action-config";
import { setActiveOuid, setActiveOuidChain } from "../../actions/action-permissions";
import configureStore from '../../store/configureStore';
import ScheduleIcon from '@material-ui/icons/Schedule';

const store = configureStore();

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    maxWidth: 275,
    marginRight: 15,
    marginBottom: 15,
    padding: 10,
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    backgroundColor: '#ffffff',
    boxShadow: 'rgb(9 30 66 / 25%) 0px 1px 1px, rgb(9 30 66 / 13%) 0px 0px 1px 1px',
    borderRadius: '3px',
    '&:hover': {
      boxShadow: 'rgb(9 30 66 / 25%) 0px 8px 16px -4px, rgb(9 30 66 / 31%) 0px 0px 1px',
      transition: 'box-shadow 0.2s ease 0s',
    },
  },
  OrchProfileCard: {
    fontSize: 14,
  },
  avatar: {
    backgroundColor: '#dc281e',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  CardActions: {
    padding: 0,
  },
});

export default function OrgunitCard(props) {
  const { Orgunit } = props;
  const classes = useStyles();
  const Icontooltip = withStyles({
    tooltip: {
      fontSize: 12,
    },
  })(Tooltip);
  const data = Orgunit.data;

  const handleOrgChange = async (identifier) => {
    const path = window.location.pathname.split('/');
    // await RedirectFunc(`/${identifier}/${path[path.length - 1]}`)
    store.dispatch(setActiveOuid(identifier));
    await store.dispatch(setActiveOuidChain(identifier));
    await store.dispatch(setActiveOiudConfigs(identifier));
    const { activeOuidCoreConfigs } = store.getState().config;
    if (activeOuidCoreConfigs.data) {
      if(activeOuidCoreConfigs.data.config.home){
        const deafaultUrl = activeOuidCoreConfigs.data.config.home;
        RedirectFunc(`/${identifier}/${deafaultUrl}`)
        return;
      }else{
        RedirectFunc(`/${identifier}`)
        return;
      }
    }else{
      RedirectFunc(`/${identifier}`)
      return;
    }
  };

  const { activeOuid } = store.getState().permission;

  return (
    <Card className={classes.root}>
      {data && (
        <div>
          <div className="OrchProfileCard">
            <div className="detailwrapper">
              <Button variant="text" className="link" onClick={() => { handleOrgChange(data.identifier); }}>
                <div className="detailHeading">{data.name}</div>
              </Button>
              {Orgunit.markedForDeletion ?
              <Icontooltip title="Requested For Deletion">
                <ScheduleIcon />
              </Icontooltip>
              :
              ""
            }
            </div>
            <div className="detailwrapper">
              {/* <div className="detailHeading">Type: </div> */}
              <div className="detailValue">{data.emailId}</div>
              <div className="detailValue">{data.mobile}</div>
            </div>
          </div>
          <CardActions disableSpacing className={classes.CardActions}>
            <Icontooltip title="Details">
              <IconButton aria-label="view" onClick={() => { history.push(`/${activeOuid}/orgunit/${data.identifier}`); }}>
                <VisibilityIcon />
              </IconButton>
            </Icontooltip>
            <Icontooltip title="Edit">
              <IconButton aria-label="add to favorites" onClick={() => { history.push(`/${activeOuid}/edit-orgunit/${data.identifier}`); }}>
                <EditIcon />
              </IconButton>
            </Icontooltip>
          </CardActions>
        </div>
      )}
    </Card>
  );
}
