import {
    CREATE_APP_USER_REQUESTED,
    CREATE_APP_USER_SUCCESS,
    CREATE_APP_USER_ERRORED,
    GET_APP_USER_DETAILS_REQUESTED,
    GET_APP_USER_DETAILS_SUCCESS,
    GET_APP_USER_DETAILS_ERRORED,
    GET_ALL_APP_USER_REQUESTED,
    GET_ALL_APP_USER_SUCCESS,
    GET_ALL_APP_USER_ERRORED,
    DELETE_APP_USER_REQUESTED,
    DELETE_APP_USER_SUCCESS,
    DELETE_APP_USER_ERRORED,
} from '../constant/action-types';
import axios from 'axios';
import { showSnackbar } from './action-snackbar';

import configureStore from '../store/configureStore';

const store = configureStore();


export const create_app_user_requested = () => ({
    type: CREATE_APP_USER_REQUESTED,
});

export const create_app_user_success = () => ({
    type: CREATE_APP_USER_SUCCESS,
});

export const create_app_user_errored = (error) => ({
    type: CREATE_APP_USER_ERRORED,
    error,
});


export const create_app_user = (payload, ouid, appId) => async (dispatch) => {
    dispatch(create_app_user_requested());
    console.log(`create_app_user`);
    payload['appId'] = appId;
    payload['ouid'] = ouid;
    const {
        apiEndpoint,
        credentials,
    } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
        const jwt = `${credentials.idToken.jwtToken}`;
        const url = `${apiEndpoint}/appuser`;
        // console.log(`jwt : ${jwt}`);
        //   console.log(`url : ${url}`);
        const method = 'post';
        const axiosHeader = {
            headers: {
                Authorization: jwt,
                'x-ouid': ouid
            },
        };
        return axios[method](
            url,
            payload,
            axiosHeader,
        )
            .then((response) => {
                //   console.log("response : ",JSON.stringify(response));
                dispatch(showSnackbar('App User created Successfully', 'success'));
                dispatch(create_app_user_success(response.data));
            })
            .catch((err) => {
                console.log('Create App User Has Errored');
                // console.error('ErrorLog : ', JSON.stringify(err));
                dispatch(showSnackbar('App User Creation Failed', 'error'));
                dispatch(create_app_user_errored(err));
            });
    }
    return null;
};



export const get_all_app_user_requested = () => ({
    type: GET_ALL_APP_USER_REQUESTED,
});

export const get_all_app_user_details_success = (payload) => ({
    type: GET_ALL_APP_USER_SUCCESS,
    payload,
});

export const get_all_app_user_details_errored = (error) => ({
    type: GET_ALL_APP_USER_ERRORED,
    error,
});


export const get_all_app_user = (username, ouid) => async (dispatch) => {
    dispatch(get_all_app_user_requested());
    console.log(`get_all_app_user`);
    const {
        apiEndpoint,
        credentials,
    } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
        const jwt = `${credentials.idToken.jwtToken}`;

        // console.log(`jwt : ${jwt}`);
        //   console.log(`url : ${url}`);
        const method = 'get';
        let axiosHeader = {};
        let url = `${apiEndpoint}/appuser/${username}`;
        axiosHeader['headers'] = {
            Authorization: jwt,
            'x-ouid': ouid
        };
        return axios[method](
            url,
            axiosHeader,
        )
            .then((response) => {
                console.log("get app user details success");
                dispatch(get_all_app_user_details_success(response.data));
            })
            .catch((err) => {
                console.log('get app user details Errored');
                // console.error('ErrorLog : ', JSON.stringify(err));
                dispatch(get_all_app_user_details_errored(err));
            });
    }
    return null;
};


export const get_app_user_details_requested = () => ({
    type: GET_APP_USER_DETAILS_REQUESTED,
});

export const get_app_user_details_success = (payload) => ({
    type: GET_APP_USER_DETAILS_SUCCESS,
    payload,
});

export const get_app_user_details_errored = (error) => ({
    type: GET_APP_USER_DETAILS_ERRORED,
    error,
});


export const get_app_user_details = (username, ouid) => async (dispatch) => {
    dispatch(get_app_user_details_requested());
    console.log(`get_app_user_details`);
    const {
        apiEndpoint,
        credentials,
    } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
        const jwt = `${credentials.idToken.jwtToken}`;

        // console.log(`jwt : ${jwt}`);
        //   console.log(`url : ${url}`);
        const method = 'get';
        let axiosHeader = {};
        let url = `${apiEndpoint}/appuser/${username}`;
        axiosHeader['headers'] = {
            Authorization: jwt,
            'x-ouid': ouid
        };
        return axios[method](
            url,
            axiosHeader,
        )
            .then((response) => {
                console.log("get app user details success");
                dispatch(get_app_user_details_success(response.data));
            })
            .catch((err) => {
                console.log('get app user details Errored');
                // console.error('ErrorLog : ', JSON.stringify(err));
                dispatch(get_app_user_details_errored(err));
            });
    }
    return null;
};


export const delete_app_user_requested = () => ({
    type: DELETE_APP_USER_REQUESTED,
});

export const delete_app_user_success = (payload) => ({
    type: DELETE_APP_USER_SUCCESS,
});

export const delete_app_user_errored = (error) => ({
    type: DELETE_APP_USER_ERRORED,
    error,
});



export const delete_app_user = (username, ouid, appId) => async (dispatch) => {
    dispatch(delete_app_user_requested());
    console.log(`delete_app_user`);
    const {
        apiEndpoint,
        credentials,
    } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
        const jwt = `${credentials.idToken.jwtToken}`;

        // console.log(`jwt : ${jwt}`);
        //   console.log(`url : ${url}`);
        const method = 'delete';
        let axiosHeader = {};
        let url = `${apiEndpoint}/appuser/${username}`;
        axiosHeader['headers'] = {
            Authorization: jwt,
            'x-ouid': ouid,
            'x-appid' : appId
        };
        return axios[method](
            url,
            axiosHeader,
        )
            .then((response) => {
                console.log("delele app user details sucess : ");
                dispatch(delete_app_user_success());
            })
            .catch((err) => {
                console.log('delele app user details Errored');
                // console.error('ErrorLog : ', JSON.stringify(err));
                dispatch(delete_app_user_errored(err));
            });
    }
    return null;
};