import React from 'react';
import { connect } from 'react-redux';
import Loader from '../../common/Loader';
import { history } from '../../../reducers';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import configureStore from '../../../store/configureStore';

const store = configureStore();

function TeamUser(props) {
  const { usersToshow } = props;
  const { showGlobalLoader } = store.getState().teamUserProfile
  const path = window.location.pathname.split('/');
  const teamId = path[path.length - 2];
  const activeOuid = path[1];

  const renderUser = (users, activeOuid, teamId) => {
    return (
        users.map((row) => (
            <TableRow key={row.data.username}>
                <TableCell component="th" scope="row">
                    <Button variant="text" onClick={() => { history.push(`/${activeOuid}/team/${teamId}/teamuser/${row.data.username.replace(/ /g, '-')}`); }}>
                        {row.data.name}
                    </Button>
                </TableCell>
                <TableCell>{row.data.username}</TableCell>
                <TableCell>{row.data.email}</TableCell>
                <TableCell>{row.data.phone_number}</TableCell>
                <TableCell>{row.data.roles}</TableCell>
                <TableCell>
                    <Button variant="contained" color="primary" onClick={() => { history.push(`/${activeOuid}/team/${teamId}/edit-teamuser/${row.data.username}`); }}>
                        Edit
                    </Button>
                </TableCell>

            </TableRow>
        ))
    );
}

  return (
    <div>
        <div className="listHeader">
            <h1 className="pageHeading">User List</h1>
            <Button onClick={() => { history.push(`/${activeOuid}/create-team-user/${teamId}`); }} variant="contained" color="primary">
                Add User
            </Button>
        </div>

    {showGlobalLoader ?
        <Loader />
        :
        (usersToshow.length !== 0) ?
            <div>
                <div className="List">
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="TableHeader">Name</TableCell>
                                    <TableCell className="TableHeader">Username</TableCell>
                                    <TableCell className="TableHeader">Email</TableCell>
                                    <TableCell className="TableHeader">Mobile</TableCell>
                                    <TableCell className="TableHeader">Roles</TableCell>
                                    <TableCell className="TableHeader"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {renderUser(usersToshow, activeOuid, teamId)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
            :
            'No Users Found'
    }
</div>
  );
}

const mapStateToProps = (state) => ({
    permission: state.permission,
    teamUserProfile: state.teamUserProfile,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamUser);