import {
  CREATE_TAG_REQUESTED,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_ERRORED,
  GET_TAGS_REQUESTED,
  GET_TAGS_SUCCESS,
  GET_TAGS_ERRORED,
  GET_PRIMARY_TAGS_SUCCESS,
  GET_SECONDARY_TAGS_SUCCESS,
  GET_PRIMARY_TAGS_REQUESTED,
  GET_SECONDARY_TAGS_REQUESTED,
  CREATE_PRIMARY_TAG_REQUESTED,
  CREATE_SECONDARY_TAG_REQUESTED,
  CREATE_PRIMARY_TAG_SUCCESS,
  CREATE_SECONDARY_TAG_SUCCESS,
  CREATE_PRIMARY_TAG_ERRORED,
  CREATE_SECONDARY_TAG_ERRORED,
} from "../constant/action-types";

const initialState = {
  showGlobalLoader: false,
  showUploadLoader: false,
  tags: [],
  primaryTags: [],
  secondaryTags: [],
  tagDetails: {},
  error: {},
};

export function tag(state = initialState, { type, payload }) {
  switch (type) {
    case CREATE_TAG_REQUESTED:
      console.log("CREATE_TAG_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
      };

    case CREATE_TAG_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case CREATE_TAG_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_TAGS_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
        tags: [],
      };

    case GET_PRIMARY_TAGS_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
        primaryTags: [],
      };

    case GET_SECONDARY_TAGS_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
        secondaryTags: [],
      };

    case GET_TAGS_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        tags: payload.items,
      };

    case GET_PRIMARY_TAGS_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        primaryTags: payload.items,
      };
    case GET_SECONDARY_TAGS_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        secondaryTags: payload.items,
      };

    case GET_TAGS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    case CREATE_PRIMARY_TAG_REQUESTED:
      console.log("CREATE_PRIMARY_TAG_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
      };

    case CREATE_PRIMARY_TAG_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case CREATE_PRIMARY_TAG_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    case CREATE_SECONDARY_TAG_REQUESTED:
      console.log("CREATE_PRIMARY_TAG_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
      };

    case CREATE_SECONDARY_TAG_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case CREATE_SECONDARY_TAG_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    default:
      return state;
  }
}
