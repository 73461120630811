import React, { Component } from "react";
import { connect } from "react-redux";
import configureStore from "../../../../store/configureStore";
import { get_all_works, get_works } from "../../../../actions/action-work";
import CommonSnackBar from "../../../common/CommonSnackBar";
import { setActiveOuid } from "../../../../actions/action-permissions";
import { get_all_orguesr } from "../../../../actions/action-orguser";
import MyTickets from "./MyTickets";
import AllTickets from "./AllTickets";
import Button from "@material-ui/core/Button";
import PopUp from "./Common/PopUp";
import CreateTicket from "./CreateTicket";
import { updateQueryStringParameter } from "../../../common/CommonFunctions";
import ProjectListCard from "./ProjectListCard";
import SprintListCard from "./SprintListCard";

const store = configureStore();

class TicketDash extends Component {
  constructor() {
    super();
    this.state = {
      currentUser: "",
      appConfig: {},
      workType: "",
      allWorkDefaultStatus: "open",
      myWorkDefaultStatus: "assigned",
      myWorksToshow: [],
      allWorksToshow: [],
      usersToshow: [],
      currentWork: {},
      createWork: false,
      prj: "",
      sprint: "",
      tabValue: 0,
    };
  }

  componentDidMount() {
    const { activeOuidConfigs } = store.getState().config;
    const appConfig = activeOuidConfigs.find(
      ({ data }) => data.identifier === "ticketapp"
    );
    const urlParams = new URLSearchParams(window.location.search);

    let allStatus = this.state.allWorkDefaultStatus;
    if (urlParams.get("allStatus")) allStatus = urlParams.get("allStatus");
    let myStatus = this.state.myWorkDefaultStatus;
    if (urlParams.get("myStatus")) myStatus = urlParams.get("myStatus");
    let prj = "";
    if (urlParams.get("prj")) prj = urlParams.get("prj");
    let sprint = "";
    if (urlParams.get("sprint")) sprint = urlParams.get("sprint");
    this.setState(
      {
        appConfig: appConfig,
        workType: appConfig.data.config.workItem,
        allWorkDefaultStatus: allStatus,
        myWorkDefaultStatus: myStatus,
        prj: prj,
        sprint: sprint,
      },
      async () => {
        this.fetchDetails();
        updateQueryStringParameter("allStatus", allStatus, null);
        updateQueryStringParameter("myStatus", myStatus, null);
        if (prj) {
          updateQueryStringParameter("prj", prj, null);
        }
        if (sprint) {
          updateQueryStringParameter("sprint", sprint, null);
        }
      }
    );
  }

  componentWillUnmount() {
    console.log("componentWillUnmount");
  }
  rerender = () => {
    const urlParams = new URLSearchParams(window.location.search);
    let prj = "";
    if (urlParams.get("prj")) prj = urlParams.get("prj");
    let sprint = "";
    if (urlParams.get("sprint")) sprint = urlParams.get("sprint");
    this.setState({
      ...this.state,
      prj: prj,
      sprint: sprint,
    });
    console.log("");
    this.fetchDetails("urlParams", urlParams);
  };

  async fetchDetails() {
    const path = window.location.pathname.split("/");
    const ouid = path[1];
    await store.dispatch(setActiveOuid(ouid));
    const { session } = store.getState().jwt.api.userSession;

    this.setState(
      {
        currentUser: session.username,
      },
      async () => {
        const { get_works } = this.props;
        await get_works(
          ouid,
          this.state.workType,
          "",
          this.state.allWorkDefaultStatus,
          "",
          this.state.prj,
          this.state.sprint
        );
        this.setState({
          allWorksToshow: store.getState().work.works,
        });
        await get_works(
          ouid,
          this.state.workType,
          "",
          this.state.myWorkDefaultStatus,
          this.state.currentUser,
          this.state.prj,
          this.state.sprint
        );
        this.setState({
          myWorksToshow: store.getState().work.works,
        });
        const { get_all_orguesr } = this.props;
        await get_all_orguesr(ouid);
        this.setState({
          usersToshow: store.getState().orgUserProfile.orgUserProfiles,
        });
      }
    );
  }

  renderWork(works, activeOuid, teamId) {
    let newRows = [];
    works.map((work, index) => {
      let newRow = work.data;
      newRow["id"] = work.data.identifier;
      newRows.push(newRow);
    });
    return newRows;
  }

  async handleAllWorkStatusChange(value) {
    console.log("this.state.prj", this.state.prj);
    console.log("status value :: ", JSON.stringify(value));

    this.setState({
      allWorkDefaultStatus: value,
    });
    updateQueryStringParameter("allStatus", value, null);
    const path = window.location.pathname.split("/");
    const ouid = path[1];
    const { get_works } = this.props;
    await get_works(
      ouid,
      this.state.workType,
      "",
      value,
      "",
      this.state.prj,
      this.state.sprint
    );
    this.setState({
      allWorksToshow: store.getState().work.works,
    });
  }

  async handlemyWorkDefaultStatusChange(value) {
    this.setState({
      myWorkDefaultStatus: value,
    });
    updateQueryStringParameter("myStatus", value, null);
    const path = window.location.pathname.split("/");
    const ouid = path[1];
    const { get_works } = this.props;
    console.log("this.state.prj", this.state.prj);
    console.log("status value :: ", JSON.stringify(value));

    await get_works(
      ouid,
      this.state.workType,
      "",
      value,
      this.state.currentUser,
      this.state.prj,
      this.state.sprint
    );
    /**await get_all_works(
      ouid,
      this.state.workType,
      "",
      value,
      this.state.currentUser
    );**/
    this.setState({
      myWorksToshow: store.getState().work.works,
    });
  }

  handleTabChange = (event, newValue) => {
    this.setState({
      tabValue: newValue,
    });
    updateQueryStringParameter("tab", newValue, null);
    updateQueryStringParameter("identifier", "", null);
  };

  a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  handleCreateWork() {
    this.setState((prevState) => ({
      createWork: !prevState.createWork,
    }));
  }

  render() {
    const { activeOuid, activeOuidChain } = store.getState().permission;
    return (
      <div>
        <Button
          onClick={() => {
            this.handleCreateWork();
          }}
          variant="contained"
          color="primary"
          className="ticketAddBtn"
        >
          Add Ticket
        </Button>
        <div>
          {this.state.prj && <h2>Project : {this.state.prj}</h2>}
          {this.state.sprint && <h2>Sprint : {this.state.sprint}</h2>}
        </div>
        <div className="TicketsPage">
          <MyTickets
            rows={this.renderWork(this.state.myWorksToshow)}
            usersToshow={this.state.usersToshow}
            defaultStatus={this.state.myWorkDefaultStatus}
            handlemyWorkDefaultStatusChange={(e) => {
              this.handlemyWorkDefaultStatusChange(e);
            }}
            workType={this.state.workType}
            rerender={this.rerender}
          />
          <AllTickets
            rows={this.renderWork(this.state.allWorksToshow)}
            usersToshow={this.state.usersToshow}
            defaultStatus={this.state.allWorkDefaultStatus}
            handleAllWorkStatusChange={(e) => {
              this.handleAllWorkStatusChange(e);
            }}
            workType={this.state.workType}
            rerender={this.rerender}
          />
          <ProjectListCard rerender={this.rerender} />
          <SprintListCard rerender={this.rerender} />
        </div>
        {this.state.createWork && (
          <PopUp
            defaultState={this.state.createWork}
            componentToPassDown={
              <CreateTicket
                usersToshow={this.state.usersToshow}
                activeOuid={activeOuid}
                activeOuidChain={activeOuidChain}
                workType={this.state.workType}
                rerender={this.rerender}
                defaultState={this.state.createWork}
                handleClose={() => {
                  this.handleCreateWork();
                }}
              />
            }
            handleClose={() => {
              this.handleCreateWork();
            }}
          />
        )}
        <CommonSnackBar />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  permission: state.permission,
  work: state.work,
  teamUserProfile: state.teamUserProfile,
});

const mapDispatchToProps = (dispatch) => ({
  get_all_orguesr: (activeOuid) => dispatch(get_all_orguesr(activeOuid)),
  get_all_works: (ouid, type, teamId, status, username) =>
    dispatch(get_all_works(ouid, type, teamId, status, username)),
  get_works: (ouid, type, teamId, status, username, pTag, sTag) =>
    dispatch(get_works(ouid, type, teamId, status, username, pTag, sTag)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketDash);
