import React, { Component } from "react";
import { connect } from "react-redux";
import configureStore from "../../../../store/configureStore";
import Sidebar from "./Sidebar";
import TicketDash from "./TicketDash";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import ProjectList from "./ProjectList";
import Project from "./Project";
import SprintList from "./SprintList";
import ProjectTickets from "./ProjectTickets";
import SprintTickets from "./SprintTickets";

const store = configureStore();

class TicketApp extends Component {
  render() {
    const { permissions } = store.getState().permission;
    return (
      <div className="page">
        <Sidebar auth={this.props.auth} permissions={permissions} />
        <section className="section">
          <div className="container">
            <div>
              <Switch>
                <Route
                  exact
                  path="/:id/apps/ticketapp"
                  render={(props) => <TicketDash />}
                />
                <Route
                  exact
                  path="/:id/apps/ticketapp/projects"
                  render={(props) => <ProjectList />}
                />
                <Route
                  exact
                  path="/:id/apps/ticketapp/project/:id"
                  render={(props) => <Project />}
                />
                <Route
                  exact
                  path="/:id/apps/ticketapp/ptickets"
                  render={(props) => <ProjectTickets />}
                />
                <Route
                  exact
                  path="/:id/apps/ticketapp/stickets"
                  render={(props) => <SprintTickets />}
                />

                <Route
                  exact
                  path="/:id/apps/ticketapp/sprints"
                  render={(props) => <SprintList />}
                />
                <Route
                  exact
                  path="/:id/apps/ticketapp/sprint/:id"
                  render={(props) => <Project />}
                />
              </Switch>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  // get_user_profile_details: (username) => dispatch(get_user_profile_details(username)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketApp);
