import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import CommonConfirm from './CommonConfirm';
import configureStore from '../../store/configureStore';
import { delete_Orgunit } from "../../actions/action-orgunit";
import { delete_work } from "../../actions/action-work";
import { delete_delete_user_profile } from "../../actions/action-userProfiles";
import { history } from '../../reducers';

const store = configureStore();


export default function CommonDelete(props) {
    const { id, type } = props;
    const [open, setOpen] = React.useState(false);
    const Icontooltip = withStyles({
        tooltip: {
            fontSize: 12,
        },
    })(Tooltip);
    const deleteItem = (id) => {
        console.log('deleteItem id', id);
        setOpen(true);
    }

 const deleteConfirmFunc = async (confirmation) => {
        // console.log('deleteConfirmFunc : ', confirmation);
        const { activeOuid } = store.getState().permission;
        setOpen(false);
        if (confirmation) {
            switch (type) {
                
                case 'Orgunit':
                    console.log('delete Orgunit');
                    await store.dispatch(delete_Orgunit(id));
                    history.push(`/${activeOuid}/orgunits`);
                    return;
                case 'work':
                    console.log('delete work');
                    await store.dispatch(delete_work(id));
                    history.push(`/${activeOuid}/leads`);
                    return;
                case 'userProfile':
                    console.log('delete userProfile');
                    await store.dispatch(delete_delete_user_profile(id));
                    history.push(`/${activeOuid}/users`);
                    return;
                default:
                    return;
            }
        }
    }

    return (
        <div>
            <Icontooltip title="Delete">
                <IconButton aria-label="add to favorites" onClick={() => { deleteItem(id); }}>
                    <DeleteIcon />
                </IconButton>
            </Icontooltip>
            <CommonConfirm
                confirmBox={open}
                closeConfirmBox={(confirmation) => deleteConfirmFunc(confirmation)}
            />
        </div>
    );
}