import {
  GET_ALL_WORK_REQUESTED,
  GET_ALL_WORK_SUCCESS,
  GET_ALL_WORK_ERRORED,
  CREATE_OR_UPDATE_WORK_REQUESTED,
  CREATE_OR_UPDATE_WORK_SUCCESS,
  CREATE_OR_UPDATE_WORK_ERRORED,
  GET_WORK_DETAILS_REQUESTED,
  GET_WORK_DETAILS_SUCCESS,
  GET_WORK_DETAILS_ERRORED,
  DELETE_WORK_REQUESTED,
  DELETE_WORK_SUCCESS,
  DELETE_WORK_ERRORED,
  CREATE_OR_UPDATE_WORKLINE_REQUESTED,
  CREATE_OR_UPDATE_WORKLINE_SUCCESS,
  CREATE_OR_UPDATE_WORKLINE_ERRORED,
  GET_ALL_WORKLINE_REQUESTED,
  GET_ALL_WORKLINE_SUCCESS,
  GET_ALL_WORKLINE_ERRORED,
} from '../constant/action-types';

const defaultState = {
  showGlobalLoader: false,
  works: [],
  workDetails: {},
  showGlobalLoaderWorkline: false,
  worklines: [],
  worklineDetails: {},
  error: {},
};

export function work(state = defaultState, action) {
  switch (action.type) {
    case GET_ALL_WORK_REQUESTED:
      console.log("GET_ALL_WORK_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_ALL_WORK_SUCCESS:
      console.log("GET_ALL_WORK_SUCCESS : ", JSON.stringify(action.payload));
      return {
        ...state,
        showGlobalLoader: false,
        works: action.payload.items
      };

    case GET_ALL_WORK_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.payload
      };

    case CREATE_OR_UPDATE_WORK_REQUESTED:
      console.log("CREATE_OR_UPDATE_WORK_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
      };

    case CREATE_OR_UPDATE_WORK_SUCCESS:
      console.log("CREATE_OR_UPDATE_WORK_SUCCESS : ");
      return {
        ...state,
        showGlobalLoader: false,
      };

    case CREATE_OR_UPDATE_WORK_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.error
      };

    case GET_WORK_DETAILS_REQUESTED:
      console.log("GET_WORK_DETAILS_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
        workDetails: {}
      };

    case GET_WORK_DETAILS_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        workDetails: action.payload
      };

    case GET_WORK_DETAILS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.error
      };

    case DELETE_WORK_REQUESTED:
      // console.log("DELETE_WORK_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
      };

    case DELETE_WORK_SUCCESS:
      // console.log("DELETE_WORK_SUCCESS : ", JSON.stringify(payload));
      return {
        ...state,
        showGlobalLoader: false,
      };

    case DELETE_WORK_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.payload
      };

    case CREATE_OR_UPDATE_WORKLINE_REQUESTED:
      console.log("CREATE_OR_UPDATE_WORKLINE_REQUESTED : ");
      return {
        ...state,
        showGlobalLoaderWorkline: true,
      };

    case CREATE_OR_UPDATE_WORKLINE_SUCCESS:
      console.log("CREATE_OR_UPDATE_WORKLINE_SUCCESS : ");
      return {
        ...state,
        showGlobalLoaderWorkline: false,
      };

    case CREATE_OR_UPDATE_WORKLINE_ERRORED:
      return {
        ...state,
        showGlobalLoaderWorkline: false,
        error: action.error
      };

      case GET_ALL_WORKLINE_REQUESTED:
        // console.log("GET_ALL_WORKLINE_REQUESTED : ");
        return {
          ...state,
          showGlobalLoaderWorkline: true,
          worklines: []
        };
  
      case GET_ALL_WORKLINE_SUCCESS:
        // console.log("GET_ALL_WORKLINE_SUCCESS : ", JSON.stringify(payload));
        return {
          ...state,
          showGlobalLoaderWorkline: false,
          worklines: action.payload.items
        };
  
      case GET_ALL_WORKLINE_ERRORED:
        return {
          ...state,
          showGlobalLoaderWorkline: false,
          error:  action.payload
        };
    default:
      return {...state}
  }
}