import {
  GET_DOCUMENT_SIGNED_URL_REQUESTED,
  GET_DOCUMENT_SIGNED_URL_SUCCESS,
  GET_DOCUMENT_SIGNED_URL_ERRORED,
} from "../constant/action-types";

const initialState = {
  showGlobalLoader: false,
  signedUrl: "",
  error: {},
};

export function docpreview(state = initialState, { type, payload }) {
  switch (type) {
    case GET_DOCUMENT_SIGNED_URL_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
        signedUrl: "",
      };

    case GET_DOCUMENT_SIGNED_URL_SUCCESS:
      console.log("GET_DOCUMENT_SIGNED_URL_SUCCESS state", state);
      console.log("GET_DOCUMENT_SIGNED_URL_SUCCESS payload", payload);

      return {
        ...state,
        showGlobalLoader: false,
        signedUrl: payload,
      };

    case GET_DOCUMENT_SIGNED_URL_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    default:
      return state;
  }
}
