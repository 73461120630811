import {
    GET_ALL_NOTIFICATION_REQUESTED,
    GET_ALL_NOTIFICATION_SUCCESS,
    GET_ALL_NOTIFICATION_ERRORED,
    UPDATE_NOTIFICATION_REQUESTED,
    UPDATE_NOTIFICATION_SUCCESS,
    UPDATE_NOTIFICATION_ERRORED,
} from '../constant/action-types';
import axios from 'axios';
import { showSnackbar } from './action-snackbar';

import configureStore from '../store/configureStore';

const store = configureStore();

export const get_all_notification_requested = () => ({
    type: GET_ALL_NOTIFICATION_REQUESTED,
});

export const get_all_notification_success = (payload) => ({
    type: GET_ALL_NOTIFICATION_SUCCESS,
    payload
});

export const get_all_notification_errored = (error) => ({
    type: GET_ALL_NOTIFICATION_ERRORED,
    error,
});

export const get_all_notification = (ouid) => async (dispatch) => {
    dispatch(get_all_notification_requested());
    console.log(`get_all_notification`);
    const {
        apiEndpoint,
        credentials,
    } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
        const jwt = `${credentials.idToken.jwtToken}`;
        const url = `${apiEndpoint}/notification`;
        // console.log(`jwt : ${jwt}`);
        // console.log(`url : ${url}`);
        const method = 'get';
        const axiosHeader = {
            headers: {
                Authorization: jwt,
                'x-ouid': ouid,
            },
        };
        return axios[method](
            url,
            axiosHeader,
        )
            .then((response) => {
                console.log("Get All Notifications Success");
                // dispatch(showSnackbar('Get All Channel Partner Successfully', 'success'));
                dispatch(get_all_notification_success(response.data));
            })
            .catch((err) => {
                console.log('Get All Orgunit Failed');
                // console.error('ErrorLog : ', JSON.stringify(err));
                dispatch(showSnackbar('Get Notifications Failed', 'error'));
                dispatch(get_all_notification_errored(err));
            });
    }
    return null;
};


export const update_notification_requested = () => ({
    type: UPDATE_NOTIFICATION_REQUESTED,
});

export const update_notification_success = (payload) => ({
    type: UPDATE_NOTIFICATION_SUCCESS,
});

export const update_notification_errored = (error) => ({
    type: UPDATE_NOTIFICATION_ERRORED,
    error,
});

export const update_notification = (notifId, ouid, payload) => async (dispatch) => {
    dispatch(update_notification_requested());
    console.log(`update_notification_requested`);
    const {
        apiEndpoint,
        credentials,
    } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
        const jwt = `${credentials.idToken.jwtToken}`;
        const url = `${apiEndpoint}/notification/${notifId}`;
        // console.log(`jwt : ${jwt}`);
        // console.log(`url : ${url}`);
        const method = 'put';
        const axiosHeader = {
            headers: {
                Authorization: jwt,
                'x-ouid': ouid,
            },
        };
        return axios[method](
            url,
            payload,
            axiosHeader,
        )
            .then((response) => {
                console.log("Update Notifications Success");
                dispatch(showSnackbar('Notification Updated Successfully', 'success'));
                dispatch(update_notification_success(response.data));
            })
            .catch((err) => {
                console.log('Update Notifications Failed');
                // console.error('ErrorLog : ', JSON.stringify(err));
                dispatch(showSnackbar('Update Notifications Failed', 'error'));
                dispatch(update_notification_errored(err));
            });
    }
    return null;
};
