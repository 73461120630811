import React, { Component } from "react";
import banner from "../assets/images/banner.svg";
import cloud from "../assets/images/cloud.svg";
import hexagon from "../assets/images/hexagon.svg";
import hierarchy from "../assets/images/hierarchy.svg";
import footerpLogo from "../assets/images/footerLogo.svg";
import footerCall from "../assets/images/footerCall.svg";
import documents from "../assets/images/documents.svg";
import ctaimage from "../assets/images/ctaimage.svg";
import cloudsolutions from "../assets/images/cloudsolutions.svg";
import awslogo from "../assets/images/awslogo.svg";
import issuetracker from "../assets/images/issuetracker.svg";
import edutech from "../assets/images/edutech.svg";
import cms from "../assets/images/cms.svg";
import hrm from "../assets/images/hrm.svg";
import accounting from "../assets/images/accounting.svg";
import salespro from "../assets/images/salespro.svg";
import ecommerce from "../assets/images/ecommerce.svg";
import questionmark from "../assets/images/questionmark.svg";
import facebook from "../assets/images/facebook.svg";
import twitter from "../assets/images/twitter.svg";
import instagram from "../assets/images/instagram.svg";
import linkdin from "../assets/images/linkdin.svg";


import Sidebar from "../layout/Sidebar";
import { connect } from "react-redux";

class Home extends Component {
  render() {
    return (
      <div className="page">
        <Sidebar auth={this.props.auth} />
        
        <section className="hero is-primary">
          <div className="container">
            <div className="split">
              <div className="heroContent">
                <h3 className="homeBannerSubHeading">Probot Business OS</h3>
                <h1 className="homeBannerHeading">Industry Specific Saas Solutions.</h1>
                <p>Probot use AWS based Saas platform to provide highly customizable industry specific software solutions</p>
                <a href="mailto:contact@cloudechosystems.com" className="proPrimaryButton">Contact</a>
              </div>

              <div className="heroMedia">
               <img src={banner} alt="banner" />
              </div>
            </div>
          </div>
        </section>

        <section className="proFeatures">
          <div className="container">
            <div className="fourCol">

              <div className="IconCard">
                <div className="icon-image">
                  <img src={cloud} alt="cloud" />
                </div>
                <h3 className="">serverless technologies</h3>
                <p>serverless architectures offer greater scalability, more flexibility, and quicker time to release, all at a reduced cost.</p>
              </div>

              <div className="IconCard">
                <div className="icon-image">
                  <img src={hexagon} alt="hexagon" />
                </div>
                <h3 className="">multitenant architecture</h3>
                <p>multi-tenant architecture allows customers to share computing resources in a public or private cloud.</p>
              </div>
              

              <div className="IconCard">
                <div className="icon-image">
                <img src={hierarchy} alt="hierarchy" />
                </div>
                <h3 className="">Organization hierarchy</h3>
                <p>we can control the data access to the records on a object based on the job role and hierarchy  of a user.</p>
              </div>
              

              <div className="IconCard">
                <div className="icon-image">
                <img src={documents} alt="documents" />
                </div>
                <h3 className="">Document management</h3>
                <p>Document management in the context of work items or organisation or user out of the box.</p>
              </div>
              
            </div>
          </div>
        </section>

        <section className="whyChooseProbot">
          <div className="container">
            <div className="split">
            <div className="wchMedia">
                <div className="heroImage">
                  <img src={cloudsolutions} alt="documents" />
                </div>
              </div>

              <div className="wchContent">
                <div className="wchHeading">
                  <h2>Cloud native platform built using <img src={awslogo} alt="documents" /></h2>                  
                </div>

                <p>Modern applications are built serverless-first, a strategy that prioritizes the adoption of serverless services,
                so you can increase agility throughout your application stack.</p>
                <div className="homeList">
                  <div className="homeListItem"><span listPointer></span>Move from idea to market, faster</div>
                  <div className="homeListItem"><span listPointer></span>Lower your costs</div>
                  <div className="homeListItem"><span listPointer></span>Adapt at scale</div>
                  <div className="homeListItem"><span listPointer></span>Build better applications, easier</div>
                </div>
                <a href="mailto:contact@cloudechosystems.com" className="proPrimaryButton">Contact</a>
              </div>


            </div>
          </div>
        </section>
        
        <section className="services-section">
          <div className="container">
            <div className="serviceContent">
            <h2>Business Applications </h2>
            <p>Probot platform provides a seamless saas solution to Drive growth & productivity to your business  some of our soultion are.</p>

            </div>

            <div className="fourCol">
              <div className="serviceCard">
                <div className="service-image">
                 <img src={issuetracker} alt="documents" />
                </div>
                <h3 className="">Issue Tracker</h3>
              </div>

              <div className="serviceCard">
                <div className="service-image">
                 <img src={edutech} alt="documents" />
                </div>
                <h3 className="">EdTech software</h3>
              </div>
              <div className="serviceCard">
                <div className="service-image">
                 <img src={cms} alt="documents" />
                </div>
                <h3 className="">Content Management System</h3>
              </div>
              <div className="serviceCard">
                <div className="service-image">
                 <img src={hrm} alt="documents" />
                </div>
                <h3 className="">Human Resource Management</h3>
              </div>
              <div className="serviceCard">
                <div className="service-image">
                 <img src={accounting} alt="documents" />
                </div>
                <h3 className="">Accounting</h3>
              </div>
              <div className="serviceCard">
                <div className="service-image">
                 <img src={salespro} alt="documents" />
                </div>
                <h3 className="">Salespro</h3>
              </div>
              <div className="serviceCard">
                <div className="service-image">
                 <img src={ecommerce} alt="documents" />
                </div>
                <h3 className="">E-Commerce Process Suite</h3>
              </div>
              <div className="serviceCard">
                <div className="service-image">
                 <img src={questionmark} alt="documents" />
                </div>
                <h3 className="">what solutions are you looking for</h3>
              </div>
              
              
            </div>
          </div>
        </section>
        <div className="footerSection">
          <div className="ctaSection">
            <div className="split">
              <div className="ctaContent">
                <h1>Industry Specific Saas Solutions.</h1>
                <p>Probot use AWS based Saas platform to provide highly customizable industry specific software solutions</p>
                <a href="mailto:contact@cloudechosystems.com" className="proPrimaryButton">Contact</a>

              </div>
              <div className="ctaMedia">
                <img src={ctaimage} alt="documents" />
              </div>

            </div>
          </div>
          <section className="proFooter">
            <div className="container">
              <div className="fourCol">
                <div className="footerCard logoCards">
                  <img src={footerpLogo} alt="documents" />
                </div>

                <div className="footerCard">
                  <h3>Our Features</h3>
                  <div className="footerLinkcWrap">
                      <a className="footerLink">Serverless Technologies</a>
                      <a className="footerLink">Multitenant Architecture</a>
                      <a className="footerLink">Organization Hierarchy</a>
                      <a className="footerLink">Document Management</a>
                  </div>
                </div>

                <div className="footerCard">
                  <h3>Our Products</h3>
                  <div className="footerLinkcWrap">
                      <a className="footerLink">Issue Tracker</a>
                      <a className="footerLink">Edtech </a>
                      <a className="footerLink">CMS</a>
                      <a className="footerLink">CRM</a>
                      <a className="footerLink">E-commerce</a>
                      <a className="footerLink">Accounting</a>

                  </div>
                </div>

                <div className="footerCard">
                  <h3>Contact Details</h3>
                  <div className="footerLinkcWrap">
                    <div className="SocialIcon">
                      <img src={footerCall} alt="documents" />
                      <a href="mailto:contact@cloudechosystems.com" className="footerLink">contact@cloudechosystems.com</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="site-info">
              <div class="container">
                <div class="site-infowrapper">
                  <div class="copyright">
                    <p>	© all right reserved to Probot.</p>
                    <span>
                      <a href="">Privacy policy</a>
                      <span> | </span>
                      <a href="">Terms & Conditions</a>
                    </span>
                  </div>
                  <div class="footer-social-media">
                    <a href="" class="social-icons"><img src={facebook} alt="documents" /></a>
                    <a href="" class="social-icons"><img src={instagram} alt="documents" /></a>
                    <a href="" class="social-icons"><img src={twitter} alt="documents" /></a>
                    <a href="" class="social-icons"><img src={linkdin} alt="documents" /></a>
                  </div>
                </div>				
              </div>
		        </div>

          </section>
          

        </div>
        
        

      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
