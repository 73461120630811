import {
    CREATE_TEAM_USER_REQUESTED,
    CREATE_TEAM_USER_SUCCESS,
    CREATE_TEAM_USER_ERRORED,
    GET_ALL_TEAM_REQUESTED,
    GET_ALL_TEAM_SUCCESS,
    GET_ALL_TEAM_ERRORED,
    GET_TEAM_USER_DETAILS_REQUESTED,
    GET_TEAM_USER_DETAILS_SUCCESS,
    GET_TEAM_USER_DETAILS_ERRORED,
    UPDATE_TEAM_USER_REQUESTED,
    UPDATE_TEAM_USER_SUCCESS,
    UPDATE_TEAM_USER_ERRORED,
} from '../constant/action-types';
import axios from 'axios';
import { showSnackbar } from './action-snackbar';

import configureStore from '../store/configureStore';

const store = configureStore();


export const create_team_user_requested = () => ({
    type: CREATE_TEAM_USER_REQUESTED,
});

export const create_team_user_success = () => ({
    type: CREATE_TEAM_USER_SUCCESS,
});

export const create_team_user_errored = (error) => ({
    type: CREATE_TEAM_USER_ERRORED,
    error,
});


export const create_team_user = (payload, ouid, teamId) => async (dispatch) => {
    dispatch(create_team_user_requested());
    console.log(`create_team_user`);
    payload['teamId'] = teamId;
    payload['ouid'] = ouid;
    const {
        apiEndpoint,
        credentials,
    } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
        const jwt = `${credentials.idToken.jwtToken}`;
        const url = `${apiEndpoint}/teamuser`;
        // console.log(`jwt : ${jwt}`);
        //   console.log(`url : ${url}`);
        const method = 'post';
        const axiosHeader = {
            headers: {
                Authorization: jwt,
                'x-ouid': ouid
            },
        };
        return axios[method](
            url,
            payload,
            axiosHeader,
        )
            .then((response) => {
                console.log('Create Team User success');
                dispatch(showSnackbar('Team User created Successfully', 'success'));
                dispatch(create_team_user_success(response.data));
            })
            .catch((err) => {
                console.log('Create Team User Has Errored');
                // console.error('ErrorLog : ', JSON.stringify(err));
                dispatch(showSnackbar('Team User Creation Failed', 'error'));
                dispatch(create_team_user_errored(err));
            });
    }
    return null;
};

export const get_all_team_requested = () => ({
    type: GET_ALL_TEAM_REQUESTED,
});

export const get_all_team_success = (payload) => ({
    type: GET_ALL_TEAM_SUCCESS,
    payload,
});

export const get_all_team_errored = (error) => ({
    type: GET_ALL_TEAM_ERRORED,
    error,
});




export const get_all_teamuser = (ouid, teamId) => async (dispatch) => {
    dispatch(get_all_team_requested());
    console.log(`get_all_teamuser`);
    const {
        apiEndpoint,
        credentials,
    } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
        const jwt = `${credentials.idToken.jwtToken}`;
        let url;
        // console.log(`jwt : ${jwt}`);
        const method = 'get';
        let axiosHeader = {};
        if (ouid && teamId) {
            url = `${apiEndpoint}/teamuser`;
            axiosHeader['headers'] = {
                Authorization: jwt,
                'x-ouid': ouid,
                'x-teamid': teamId,
            };
        } else {
            url = `${apiEndpoint}/teamuser`;
            axiosHeader['headers'] = {
                Authorization: jwt,
            };
        }
        // console.log(`url : ${url}`);
        return axios[method](
            url,
            axiosHeader,
        )
            .then((response) => {
                console.log('get all team users Success');
                dispatch(get_all_team_success(response.data));
            })
            .catch((err) => {
                console.log('get all team users Has Errored');
                // console.error('ErrorLog : ', JSON.stringify(err));
                dispatch(get_all_team_errored(err));
            });
    }
    return null;
};


export const get_team_user_details_requested = () => ({
    type: GET_TEAM_USER_DETAILS_REQUESTED,
});

export const get_team_user_details_success = (payload) => ({
    type: GET_TEAM_USER_DETAILS_SUCCESS,
    payload,
});

export const get_team_user_details_errored = (error) => ({
    type: GET_TEAM_USER_DETAILS_ERRORED,
    error,
});


export const get_team_user_details = (username, ouid, teamId) => async (dispatch) => {
    dispatch(get_team_user_details_requested());
    console.log(`get_team_user_details`);
    const {
        apiEndpoint,
        credentials,
    } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
        const jwt = `${credentials.idToken.jwtToken}`;
        let url;
        // console.log(`jwt : ${jwt}`);
        //   console.log(`url : ${url}`);
        const method = 'get';
        let axiosHeader = {};
        if (ouid) {
            url = `${apiEndpoint}/teamuser/${username}`;
            axiosHeader['headers'] = {
                Authorization: jwt,
                'x-ouid': ouid,
                'x-teamid': teamId
            };
        }
        return axios[method](
            url,
            axiosHeader,
        )
            .then((response) => {
                console.log('get team user details success');
                dispatch(get_team_user_details_success(response.data));
            })
            .catch((err) => {
                console.log('get team user details Errored');
                // console.error('ErrorLog : ', JSON.stringify(err));
                dispatch(get_team_user_details_errored(err));
            });
    }
    return null;
};

export const update_team_user_profile_requested = () => ({
    type: UPDATE_TEAM_USER_REQUESTED,
});

export const update_team_user_profile_success = () => ({
    type: UPDATE_TEAM_USER_SUCCESS,
});

export const update_team_user_profile_errored = (error) => ({
    type: UPDATE_TEAM_USER_ERRORED,
    error,
});



export const update_team_user_profile = (payload, username, ouid, teamId) => async (dispatch) => {
    dispatch(update_team_user_profile_requested());
    console.log(`update_team_user_profile`);
    const {
        apiEndpoint,
        credentials,
    } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
        const jwt = `${credentials.idToken.jwtToken}`;
        const url = `${apiEndpoint}/teamuser/${username}`;
        // console.log(`jwt : ${jwt}`);
        //   console.log(`url : ${url}`);
        const method = 'put';
        const axiosHeader = {
            headers: {
                Authorization: jwt,
                'x-ouid': ouid,
                'x-teamid': teamId
            },
        };
        return axios[method](
            url,
            payload,
            axiosHeader,
        )
            .then((response) => {
                console.log('Update Team Profile Success');
                dispatch(showSnackbar('User Updated Successfully', 'success'));
                dispatch(update_team_user_profile_success(response.data));
            })
            .catch((err) => {
                console.log('Update Team Profile Has Errored');
                // console.error('ErrorLog : ', JSON.stringify(err));
                dispatch(showSnackbar('User Upadation Failed', 'error'));
                dispatch(update_team_user_profile_errored(err));
            });
    }
    return null;
};


