import React, { Component } from 'react';

export default class PageNotFound extends Component {


  render() {
    return (
        <section className="section">
          <div className="container">
            <div>Oops! Page Not Found</div>
            <div>404</div>
            <div>We  are sorry, but the page you requested was not found...</div>
          </div>
        </section>
    )
  }
}
