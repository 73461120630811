import React, { Component, Fragment } from 'react';
import Product from './Product';
import axios from "axios";
const config = require('../config');

export default class Products extends Component {

  state = {
    newproduct: null,
    data: []
  }

  fetchProducts = async () => {
    // add call to AWS API Gateway to fetch products here
    // then set them in state
    try {
      const res = await axios.get(`${config.api.invokeUrl}/Orgunit`,
      { headers: { 
        Authorization: 'eyJraWQiOiI4c21qejFETDBLT0FPbUh3cmtDcE9kV0Q5dkZ1MlFXNU9jejlSOUZhbjNvPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI3NjFlZDM0YS1hNTc0LTRmMmQtOGQxYS01ZjJmYjIzM2JjZmIiLCJhdWQiOiI0M291cXN1NjZmNXZta245aTBxcXNzMXAwaCIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJldmVudF9pZCI6IjY2MDAyMTk3LWZlOWQtNDZhOC1iYzcyLTVkNWE0MDIxNmMzYyIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjExNTc5MTQ1LCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV90V2ZLaXBRc20iLCJjb2duaXRvOnVzZXJuYW1lIjoiYmlqdXIiLCJleHAiOjE2MTE2NjA2MTgsImlhdCI6MTYxMTY1NzAxOCwiZW1haWwiOiJiaWp1LnJhbWFjaGFuZHJhbkBjbG91ZGVjaG9zeXN0ZW1zLmNvbSJ9.YpRG8Hf96JRrkGCjogkGX7teSAjOMFHrbMW3qMoIfMMz3uZOSqh4-Fx7FMGM4WIwptIpyG37ZBqYV_PHJLE5bA5JoENpiPddmKNNGuIa21mVQbYTwBuahxAEUwngUrcuvbtK9A4Q8YfMmyv1sBZ8jUlAmAMbxsFIXixj6S84tmaRSUCW3Z-2vmdMZJK0uK-fOr0YqwN9zG6aGlpczX44GELSL5tf00egAWQUlPe2h0yn3_-1E2mcwoZd73ZBk9oj90E4Kshn061FA3exeic8HPu68G-xVV0nqr7xuxiIENIs7jGzhZYwkRXNjGuqgSSwA8JcHuQsUwnY5PQP287QEQ' }}
      );
      const products = res.data.body;
      this.setState({ products: res });
      console.log("res", products);
    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  }

  componentDidMount = () => {
    this.fetchProducts();
  }

  render() {
    return (
      <Fragment>
        <section className="section">
          <div className="container">
            <h1>Energy Products</h1>
            <p className="subtitle is-5">Invest in a clean future with our efficient and cost-effective green energy products:</p>
            <br />
            <div className="columns">
              <div className="column">
                <div className="tile is-ancestor">
                  <div className="tile is-4 is-parent  is-vertical">
                    { 
                      this.state.products && this.state.products.length > 0
                      ? this.state.products.map(product => <Product name={product.productname} id={product.id} key={product.id} />)
                      : <div className="tile notification is-warning">No products available</div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    )
  }
}
