import React, { useEffect } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Auth } from "aws-amplify";
import { history } from "../../reducers";
import configureStore from "../../store/configureStore";
import { resetState } from "../../actions";
import {
  setActiveOuid,
  setActiveOuidChain,
} from "../../actions/action-permissions";
import { setActiveOiudConfigs } from "../../actions/action-config";
import { RedirectFunc } from "../common/CommonFunctions";
import probotlogo from "../assets/images/probotlogo.png";

// Images
import Orgunit from "../assets/images/diagram.png";
import Manageuser from "../assets/images/group.png";
import OrgUsers from "../assets/images/orgusers.png";
import PlanIcon from "../assets/images/board.png";
import AuthuserIcon from "../assets/images/group.png";
import Queue from "../assets/images/queue.png";
import Settings from "../assets/images/settings.png";
import Star from "../assets/images/star.png";
import Docs from "../assets/images/docs.png";
import Team from "../assets/images/teamwork.png";
import Courses from "../assets/images/courses.png";

import AccountCircle from "@material-ui/icons/AccountCircle";

// Material Ui
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Badge from "@material-ui/core/Badge";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
// import SidebarTree from '../common/SidebarTree';
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import Topbar from "./Topbar";
// import { Star } from '@material-ui/icons';

// const apps = require('../pages/Apps');

const store = configureStore();

const drawerWidth = 240;
const headerHeight = 75;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#fff',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    width: "100%",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    marginTop: headerHeight,
    position: "relative",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    top: 64,
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  grow: {
    flexGrow: 1,
  },

  sectionDesktop: {
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  select: {
    color: "#fff",
    "&:before": {
      borderColor: "#fff",
    },
    "&:after": {
      borderColor: "#fff",
    },
  },
  icon: {
    fill: "#fff",
  },
  treeroot: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400,
    padding: 20,
  },
}));

function MiniDrawer(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [anchorProfile, setAnchorProfile] = React.useState(null);
  const openProfile = Boolean(anchorProfile);
  const isAuthenticated = props.auth.isAuthenticated;
  const { activeOuid, userRoles } = store.getState().permission;
  const { activeOuidCoreConfigs } = store.getState().config;
  const { session } = store.getState().jwt.api.userSession;

  useEffect(() => {
    setActiveOuidValue(store.getState().permission.activeOuid);
  }, [JSON.stringify(props)]);

  const [activeOuidValue, setActiveOuidValue] = React.useState(activeOuid);

  const handleDropDown = async (event) => {
    setActiveOuidValue(event.target.value);
    await store.dispatch(setActiveOuid(event.target.value));
    await store.dispatch(setActiveOuidChain(event.target.value));
    await store.dispatch(setActiveOiudConfigs(event.target.value));
    const { activeOuidCoreConfigs } = store.getState().config;
    if (activeOuidCoreConfigs.data) {
      const deafaultUrl = activeOuidCoreConfigs.data.config.home;
      RedirectFunc(`/${event.target.value}/${deafaultUrl}`);
      return;
    } else {
      RedirectFunc(`/${event.target.value}`);
      return;
    }
  };

  const renderMenuTtem = () => {
    const activeOuidExist = userRoles.find(({ ouid }) => ouid === activeOuid);
    let renderOuids = userRoles;
    if (activeOuidExist) {
      renderOuids = userRoles;
    } else {
      renderOuids.push({ ouid: activeOuid, roles: [] });
    }
    return renderOuids.map((item, index) => (
      <MenuItem key={item.ouid} value={item.ouid}>
        {item.ouid}
      </MenuItem>
    ));
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleProfileMenu = (event) => {
    setAnchorProfile(event.currentTarget);
  };

  const handleProfileClose = () => {
    setAnchorProfile(null);
  };

  const handleLogOut = async (event) => {
    event.preventDefault();
    try {
      Auth.signOut();
      props.auth.setAuthStatus(false);
      props.auth.setUser(null);
      store.dispatch(resetState());
      history.push("/");
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        elevation={0}
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <img src={probotlogo} alt="probotlogo" />
          {activeOuidValue ? <Topbar /> : ""}
          <div className={classes.grow} />
          {isAuthenticated && (
            <div>
              <FormControl style={{ minWidth: 120 }}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={activeOuidValue}
                  onChange={handleDropDown}
                  className={classes.select}
                  inputProps={{
                    classes: {
                      icon: classes.icon,
                    },
                  }}
                >
                  {userRoles.length !== 0 ? (
                    renderMenuTtem()
                  ) : (
                    <MenuItem value="">No item Found</MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
          )}

          <div className={classes.sectionDesktop}>
            {isAuthenticated && (
              <div>
                <IconButton color="inherit">
                  <NotificationsIcon
                    onClick={() => {
                      history.push(`/${activeOuid}/notifications`);
                    }}
                  />
                </IconButton>
              </div>
            )}
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleProfileMenu}
              color="primary"
            >
              <AccountCircle />
            </IconButton>
            {isAuthenticated && (
              <Menu
                id="menu-appbar"
                anchorEl={anchorProfile}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={openProfile}
                onClose={handleProfileClose}
              >
                <MenuItem
                  onClick={() => {
                    history.push(`/${activeOuid}/my-profile`);
                  }}
                >
                  My Profile
                </MenuItem>
                <MenuItem onClick={handleLogOut}>Logout</MenuItem>
              </Menu>
            )}
            {!isAuthenticated && (
              <Menu
                id="menu-appbar"
                anchorEl={anchorProfile}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={openProfile}
                onClose={handleProfileClose}
              >
                <MenuItem
                  onClick={() => {
                    history.push("/login");
                  }}
                >
                  Login
                </MenuItem>
              </Menu>
            )}
            {isAuthenticated && <div>{session.username}</div>}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

const mapStateToProps = (state) => ({
  permission: state.permission,
  jwt: state.jwt,
  config: state.config,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MiniDrawer);
