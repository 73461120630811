import { 
  SET_ALL_PERMISSIONS,
  SET_ACTIVE_OUID,
  SET_USER_ROLES,
  SET_ACTIVE_OUID_CHAIN,
  CLEAR_ACTIVE_OUID_CHAIN,
  SET_ACTIVE_TEAMID,
} from '../constant/action-types';

import axios from 'axios';

import configureStore from '../store/configureStore';

const store = configureStore();


export function permissionsErrored(bool) {
  return {
    type: 'PERMISSIONS_ERROR',
    permissionsHasErrored: bool,
  };
}

export function setAllPermissions(permissions) {
  // console.log("SET_ALL_PERMISSIONS : ", JSON.stringify(permissions));
  return {
    type: SET_ALL_PERMISSIONS,
    permissions,
  };
}

export function setActiveOuid(id) {
  return {
    type: SET_ACTIVE_OUID,
    id,
  };
}

export function setActiveTeamId(id) {
  return {
    type: SET_ACTIVE_TEAMID,
    id,
  };
}

export function setUserRole(roles, units) {
  return {
    type: SET_USER_ROLES,
    roles,
    units,
  };
}

export function fetchPermissions() {
  return (dispatch) => {
    const url = '/permissions';
    // console.log(url);
  };
}


export function setActiveOuidChainSuccess(data) {
  return {
    type: SET_ACTIVE_OUID_CHAIN,
    data,
  };
}

export function clearActiveOuidChain() {
  return {
    type: CLEAR_ACTIVE_OUID_CHAIN,
  };
}

export const setActiveOuidChain = (id) => async (dispatch) => {
  console.log(`setActiveOuidChain`);
  dispatch(clearActiveOuidChain());
  const {
    apiEndpoint,
    credentials,
  } = store.getState().jwt.api;
  if (apiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    const url = `${apiEndpoint}/orgunit/${id}`;
    // console.log(`jwt : ${jwt}`);
    // console.log(`url : ${url}`);
    const method = 'get';
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        'x-ouid' : id,
      },
    };
    return axios[method](
      url,
      axiosHeader,
    )
      .then((response) => {
        console.log("Get Orgunit Details Success");
        // dispatch(showSnackbar('Get All Channel Partner Successfully', 'success'));
        dispatch(setActiveOuidChainSuccess(response.data.data));
      })
      .catch((err) => {
        console.error('ErrorLog : ', JSON.stringify(err));
      });
  }
  return null;
};


