import React, { Component } from 'react';
import { connect } from 'react-redux';
import configureStore from '../../../store/configureStore';
import { get_team_details } from '../../../actions/action-team';
import Loader from '../../common/Loader';
import { history } from '../../../reducers';
import Sidebar from '../../layout/Sidebar';
import CommonDelete from '../../common/CommonDelete';
import { timeFormat } from '../../common/CommonFunctions'
import CommonSnackBar from "../../common/CommonSnackBar";
import { hideSnackbar } from '../../../actions/action-snackbar';
import {Paper} from '@material-ui/core';

const store = configureStore();

class TeamDetails extends Component {

    constructor() {
        super();
        this.state = {
            teamDetails: {},
        };
    }

    componentDidMount() {
        if (!this.props.auth.isAuthenticated) {
            const path = window.location.pathname;
            history.push(`/login?redirect=${path}`);
            return
        }
        this.fetchDetails();
    }

    componentDidUpdate() {
        console.log("componentDidUpdate");
    }
    componentWillUnmount() {
        // store.dispatch(hideSnackbar());
    }

    async fetchDetails() {
        const path = window.location.pathname.split('/');
        const id = path[path.length - 1];
        const activeOuid = path[1];
        const { get_team_details } = this.props;
        const { activeOuidChain } = store.getState().permission;
        await get_team_details(id, activeOuid, activeOuidChain);
        const { teamDetails } = store.getState().team;
        this.setState({
            teamDetails: teamDetails,
        });
    }

    renderDetails(details) {
        return Object.keys(details).map(function (keyName, keyIndex) {
            return (
                <div key={keyName} className="detailsItem">
                    <div className="detailsKey">
                        {keyName} {" : "}
                    </div>
                    {keyName === 'lastModifiedDate' || keyName === 'createdDate' ?
                        <div className="detailsValue">
                            {timeFormat(details[keyName])}
                        </div>
                        :
                        <div className="detailsValue">
                            {details[keyName]}
                        </div>
                    }
                </div>
            )
        });
    }

    render() {
        const { showGlobalLoader } = store.getState().team;
        const { permissions } = store.getState().permission;
        const path = window.location.pathname.split('/');
        const id = path[path.length - 1];
        return (
            <div className="page">
                <Sidebar
                    auth={this.props.auth}
                    permissions={permissions}
                />
                <section className="section">
                    {showGlobalLoader ?
                        <Loader />
                        : <div className="container">
                            <Paper component="div" className="paper">
                                <div className="listHeader">
                                    <h1>Team Details</h1>
                                    {this.state.teamDetails.data ?
                                        this.state.teamDetails.markedForDeletion ?
                                            <div>Team Already Requested For Deletion</div>
                                            :
                                            ""
                                            // <CommonDelete
                                            //     id={id}
                                            //     type="Orgunit"
                                            // />
                                        :
                                        ""
                                    }
                                </div>
                                {this.state.teamDetails.data ? this.renderDetails(this.state.teamDetails.data) : 'No Team details Found'}
                            </Paper>
                        </div>
                    }
                    <CommonSnackBar />
                </section>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    team: state.team,
    permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
    get_team_details: (id, activeOuid, activeOuidChain) => dispatch(get_team_details(id, activeOuid, activeOuidChain)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamDetails);

