/* eslint-disable import/prefer-default-export */
import { 
  SET_ALL_PERMISSIONS,
  SET_ACTIVE_OUID,
  SET_USER_ROLES,
  SET_ACTIVE_OUID_CHAIN,
  CLEAR_ACTIVE_OUID_CHAIN,
  SET_ACTIVE_TEAMID,
} from '../constant/action-types';

const defaultState = {
  policies: [],
  teamPolicies: [],
  appPolicies: [],
  activeOuid:'',
  activeTeamId:'',
  userRoles :[],
  units:[],
  activeOuidChain:[],
  activeOuidType:'',
};
// The Default Filter.
export function permission(state = defaultState, action) {
  switch (action.type) {
    case SET_ALL_PERMISSIONS:
      console.log("SET_ALL_PERMISSIONS :::",action.permissions);
      return {
        ...state,
        policies: action.permissions.policies,
        teamPolicies: action.permissions.teamPolicies,
        appPolicies: action.permissions.appPolicies,
      };

    case SET_ACTIVE_OUID:
      console.log("SET_AACTIVE_OUID");
      return {
        ...state,
        activeOuid: action.id,
      };

      case SET_ACTIVE_TEAMID:
      console.log("SET_ACTIVE_TEAMID");
      return {
        ...state,
        activeTeamId: action.id,
      };
      
    case SET_USER_ROLES:
      console.log("SET_USER_ROLES");
      return {
        ...state,
        userRoles: action.roles,
        units: action.units,
      };

      case SET_ACTIVE_OUID_CHAIN:
        console.log("SET_ACTIVE_OUID_CHAIN");
        return {
          ...state,
          activeOuidChain: action.data.ouidChain,
          activeOuidType: action.data.type
        };
        case CLEAR_ACTIVE_OUID_CHAIN:
          console.log("CLEAR_ACTIVE_OUID_CHAIN");
          return {
            ...state,
            activeOuidChain: [],
            activeOuidType:''
          };

    default:
    return state;
  }
}
