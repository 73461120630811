import React from 'react';
import ReactHlsPlayer from 'react-hls-player';

export default function Player(props) {

    const { urlSrc } = props;

    const playerRef = React.useRef();

    React.useEffect(() => {
      function fireOnVideoStart() {
        // Do some stuff when the video starts/resumes playing
        console.log("Do some stuff when the video starts/resumes playing")
      }
  
      playerRef.current.addEventListener('play', fireOnVideoStart);
  
      return playerRef.current.removeEventListener('play', fireOnVideoStart);
    }, []);
  
    React.useEffect(() => {
      function fireOnVideoEnd() {
        // Do some stuff when the video ends
        console.log("Do some stuff when the video ends")
      }
  
      playerRef.current.addEventListener('ended', fireOnVideoEnd);
  
      return playerRef.current.removeEventListener('ended', fireOnVideoEnd);
    }, []);


    return (
        <ReactHlsPlayer
        playerRef={playerRef}
        src={urlSrc}
        autoPlay={false}
        controls={true}
        width="100%"
        height="400px"
      />
    );
}