import React, { Component } from 'react';
import banner from '../assets/images/bannerimg.png';
import Sidebar from '../layout/Sidebar';

export default class UserHome extends Component {
    render() {
        return (
            <div className="page">

                <div className="hero is-primary">
                    <div className="hero-body">
                    <div>
                        <img src={banner} alt="banner" />
                    </div>
                    <div >
                        <h1>Welcome to Probot</h1>
                        <p>You dont have any access orgs. Please contact administrator for access</p>
                    </div>
                    </div>
                </div>
                
            </div>
        )
      }
}