import { JWT_SET, UN_JWT_SET, JWT_ERRORED } from '../constant/action-types';
import config from '../config';

const initialState = {
  api: {
    apiEndpoint: `${config.api.invokeUrl}`,
    userSession : '',
    credentials: '',
    error: null,
  },
};

export function jwt(state = initialState, { type, payload }) {
  switch (type) {
    case JWT_SET:
      console.log("JWT_SET",payload.session.signInUserSession.idToken.jwtToken);
      return {
        ...state,
        api: {
          apiEndpoint: `${config.api.invokeUrl}`,
          userSession : payload,
          credentials: payload.session.signInUserSession,
          error: null,
        },
      };

      case UN_JWT_SET:
        console.log("UN_JWT_SET",);
        return {
          ...state,
          api: {
            apiEndpoint: `${config.api.invokeUrl}`,
            userSession : '',
            credentials: '',
            error: null,
          },
        };
  
    case JWT_ERRORED:
      return {
        ...state,
        api: {
          credentials: '',
          error: payload.error,
        },
      };
  
    default:
      return state;
    }
}
