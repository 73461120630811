import {
  SHOW_SNACKBAR,
  HIDE_SNACKBAR,
} from '../constant/action-types';

const defaultState = {
  snackbarOpen: false,
  snackbarMessage: '',
  snackbarType: '',
};

export const snackbar = (state = defaultState, action) => {
  switch (action.type) {
  case SHOW_SNACKBAR: {
    console.log('Action :', action.payload);
    const { snackbarMessage, snackbarType } = action.payload;

    return {
      ...state,
      snackbarOpen: true,
      snackbarMessage,
      snackbarType,
    };
  }

  case HIDE_SNACKBAR: {
    return {
      ...state,
      snackbarOpen: false,
      snackbarMessage: '',
      snackbarType: '',
    };
  }

  default: {
    return state;
  }
  }
};
