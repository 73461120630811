import React, { Component } from 'react';
import { connect } from 'react-redux';
import OrgunitCard from '../../common/OrgunitCard';
import { get_all_Orgunit } from '../../../actions/action-orgunit';
import configureStore from '../../../store/configureStore';
import SearchField from '../../common/SearchField';
import Loader from '../../common/Loader';
import Button from '@material-ui/core/Button';
import { history } from '../../../reducers';
import Sidebar from '../../layout/Sidebar';
import axios from 'axios';
import { setActiveOuid, setActiveOuidChain } from "../../../actions/action-permissions";
import CommonSnackBar from "../../common/CommonSnackBar";
import { hideSnackbar } from '../../../actions/action-snackbar';
import { setActiveOiudConfigs } from "../../../actions/action-config";


const store = configureStore();

class Orgunit extends Component {
  constructor() {
    super();
    this.state = {
      orgunitsToShow: '',
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      const path = window.location.pathname;
      history.push(`/login?redirect=${path}`);
      return
    }
    this.unregisterHistoryListener = history.listen((location, action) => {
      // console.log('on route change : ',location);
      // console.log('history ', history);
      const path = window.location.pathname.split('/');
    if(path[path.length - 1] === "orgunits"){
      this.fetchDetailsBasedOnUrl();
    }
      
    });
    this.fetchDetails();
  }
  componentWillUnmount() {
    console.log("componentWillUnmount");
    this.unregisterHistoryListener()
    store.dispatch(hideSnackbar());
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }

  async fetchDetails() {
    const { get_all_Orgunit } = this.props;
    const { activeOuid } = store.getState().permission;
    await get_all_Orgunit(activeOuid);
    const { orgunits } = store.getState().orgunit;
    this.setState({
      orgunitsToShow: orgunits,
    });
  }

  async fetchDetailsBasedOnUrl() {
    console.log("fetchDetailsBasedOnUrl");
    const path = window.location.pathname.split('/');
    const urlOuid = path[1];
    if (path[path.length - 1] === "orgunits") {
      const { get_all_Orgunit } = this.props;
      await get_all_Orgunit(urlOuid);
      const { orgunits } = store.getState().orgunit;
      this.setState({
        orgunitsToShow: orgunits,
      }, async () => {
        await store.dispatch(setActiveOuid(urlOuid));
        await store.dispatch(setActiveOuidChain(urlOuid));
        await store.dispatch(setActiveOiudConfigs(urlOuid));
        const { activeOuidCoreConfigs } = store.getState().config;
        if (activeOuidCoreConfigs.data && path[path.length - 1] !== "orgunits") {
          const deafaultUrl = activeOuidCoreConfigs.data.config.home;
          this.props.history.push(`/${urlOuid}/${deafaultUrl}`);
        }
      });
    }
  }

  renderOrgunit(Orgunit) {
    console.log("Orgunits : ", JSON.stringify(Orgunit));
    return Orgunit.map((item) => (
      <div>
        <OrgunitCard key={item.identifier}
          Orgunit={item}
        />
      </div>
    ));
  }

  search(keyword, orgunits) {
    if (keyword) {
      const searchPattern = new RegExp(keyword.map((term) => `(?=.*${term})`).join(''), 'i');
      if (orgunits) {
        this.setState({
          orgunitsToShow: orgunits.filter((orgunit) => orgunit.data.name.match(searchPattern)),
        });
      }
    } else {
      this.setState({
        orgunitsToShow: orgunits,
      });
    }
  }
  handleChangeStatus({ meta, file }, status) {
    console.log("status", status, meta, file)
  }
  async handlePut(response, f) {
    const url = response.data.uploadURL;
    // console.log('file', f);
    const body = f;
    const method = 'put';
    await axios[method](
      url,
      body
    )
      .then((response) => {
        // console.log("response : ",JSON.stringify(response));
        // console.log('Response Upload', response);

      })
      .catch((err) => {
        console.error('ErrorLog : ', JSON.stringify(err));
      });

  }
  async handleSubmit(files) {
    // console.log(files)
    const f = files[0];
    const {
      apiEndpoint,
      credentials,
    } = store.getState().jwt.api;
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${apiEndpoint}/document?signedUrl=true`;
    const method = 'get';
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "Content-type": "image/jpeg",
      },
    };

    const response = await axios[method](
      url,
      axiosHeader,
    )
    const body = f['file'];
    url = response.data.uploadURL;
    const postHeader = {
      headers: {
        "Content-type": "image/jpeg",
      }
    }
    const uploadresponse = axios['put'](
      url,
      body,
      postHeader
    )
    console.log(uploadresponse);
    // .then((response) => {
    //   // console.log("response : ",JSON.stringify(response));
    //   console.log('response',response);
    //   this.handlePut(response, f['file']);

    // })
    // .catch((err) => {
    //   console.error('ErrorLog : ', JSON.stringify(err));
    // });


  }

  render() {
    const { orgunits, showGlobalLoader } = store.getState().orgunit;
    const { permissions } = store.getState().permission;
    const { activeOuid } = store.getState().permission;
    return (
      <div className="page">
        <Sidebar
          auth={this.props.auth}
          permissions={permissions}
        />
        <section className="section">

          {showGlobalLoader ?
            <Loader />
            : <div className="container">
              <div className="listHeader">
                <h1 className="pageHeading">CHILD ORGANISATIONS</h1>
                <SearchField onChange={(e) => this.search(e.target.value.split(' '), orgunits)} />
                <Button onClick={() => { history.push(`/${activeOuid}/register-orgunit`); }} variant="contained" color="primary">
                  Add new
                </Button>
              </div>
              {this.state.orgunitsToShow &&
                <div>
                  <div className="List">
                    {(this.state.orgunitsToShow.length !== 0) ? this.renderOrgunit(this.state.orgunitsToShow) : 'No OrgUinits Found'}
                  </div>
                </div>
              }
            </div>}
          <CommonSnackBar />
        </section>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  permission: state.permission,
  orgunit: state.orgunit,
});

const mapDispatchToProps = (dispatch) => ({
  get_all_Orgunit: (activeOuid) => dispatch(get_all_Orgunit(activeOuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Orgunit);
