import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import configureStore from "../../../../store/configureStore";
import Loader from "../../../common/Loader";
import { history } from "../../../../reducers";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { DataGrid } from "@material-ui/data-grid";
import {
  timeFormat,
  updateQueryStringParameter,
} from "../../../common/CommonFunctions";
import TicketDetails from "./TicketDetails";

const store = configureStore();

const workColumns = [
  {
    field: "title",
    headerName: "Title",
    width: 260,
  },
  // {
  //   field: "type",
  //   headerName: "Type",
  //   width: 150,
  //   editable: false,
  // },
  {
    field: "primaryTag",
    headerName: "Project",
    width: 120,
    editable: false,
  },
  {
    field: "secondaryTag",
    headerName: "Sprint",
    width: 110,
    editable: false,
  },
  {
    field: "workstatus",
    headerName: "Status",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 120,
    valueGetter: (params) =>
      `${params.getValue(params.id, "status") || ""} ${
        params.getValue(params.id, "status") === "ASSIGNED" ? ">" : ""
      } ${params.getValue(params.id, "username") || ""}`,
  },
  // {
  //   field: "created_Date",
  //   headerName: "Created Date",
  //   type: "number",
  //   width: 200,
  //   editable: false,
  //   valueGetter: (params) =>
  //     `${timeFormat(params.getValue(params.id, "createdDate")) || ""}`,
  // },
  // {
  //   field: "createdBy",
  //   headerName: "Created By",
  //   width: 150,
  //   editable: false,
  // },
];

function AllTickets(props) {
  const {
    workContext,
    rows,
    defaultStatus,
    usersToshow,
    handleAllWorkStatusChange,
    workType,
    rerender,
  } = props;
  const { showGlobalLoader } = store.getState().work;

  const [workDash, setWorkDash] = useState(false);
  const [currentWork, setCurrentWork] = useState();
  const [selectionModel, setSelectionModel] = React.useState();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlIdentifier = urlParams.get("identifier");
    if (urlIdentifier && rows.length !== 0) {
      setWorkDash(true);
      const actveData = rows.find(
        ({ identifier }) => identifier === urlIdentifier
      );
      setCurrentWork(actveData);
      setSelectionModel(urlIdentifier);
    }
  }, [JSON.stringify(rows)]);

  const openWorkDash = (data) => {
    console.log("openWorkDash : ", data.row);
    setWorkDash(true);
    setCurrentWork(data.row);
    setSelectionModel(data.row.id);
    updateQueryStringParameter("identifier", data.row.id, null);
  };

  const closeWorkDash = () => {
    console.log("closeWorkDas");
    setWorkDash(false);
    setCurrentWork();
    updateQueryStringParameter("identifier", "", null);
  };

  return (
    <Paper className="TicketTablePaper">
      <div className="listHeader">
        <div>
          <h3 className="pageHeading">All Tickets</h3>
          <div>{rows.length} Ticket</div>
        </div>
        <FormControl variant="outlined" className="selectBox">
          <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={defaultStatus}
            onChange={(event) => handleAllWorkStatusChange(event.target.value)}
            label="Status"
          >
            <MenuItem value="open">Open</MenuItem>
            <MenuItem value="assigned">Assigned</MenuItem>
            <MenuItem value="in-progress">Inprogress</MenuItem>
            <MenuItem value="done">Done</MenuItem>
            <MenuItem value="cancelled">Cancelled</MenuItem>
          </Select>
        </FormControl>
      </div>
      {showGlobalLoader ? (
        <Loader />
      ) : (
        <div className="formList">
          {rows !== 0 ? (
            <div style={{ height: 500, width: "100%" }}>
              <DataGrid
                rows={rows}
                columns={workColumns}
                pageSize={10}
                disableSelectionOnClick
                onCellClick={(e) => {
                  openWorkDash(e);
                }}
                selectionModel={selectionModel}
                hideFooterSelectedRowCount
                onSelectionModelChange={(selection) => {
                  if (selection.length > !2) {
                    let lastElement = selection.pop();
                    setSelectionModel(lastElement);
                  } else {
                    let lastElement = selection.pop();
                    setSelectionModel(lastElement);
                  }
                }}
              />
            </div>
          ) : (
            "No work queues....."
          )}
          <div>
            {workDash && currentWork && (
              <TicketDetails
                workContext={workContext}
                work={currentWork}
                usersToshow={usersToshow}
                closeWorkDash={() => {
                  closeWorkDash();
                }}
                workType={workType}
                rerender={rerender}
              />
            )}
          </div>
        </div>
      )}
    </Paper>
  );
}

const mapStateToProps = (state) => ({
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AllTickets);
