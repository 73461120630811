import { 
    GET_ALL_NOTIFICATION_REQUESTED,
    GET_ALL_NOTIFICATION_SUCCESS,
    GET_ALL_NOTIFICATION_ERRORED,
    UPDATE_NOTIFICATION_REQUESTED,
    UPDATE_NOTIFICATION_SUCCESS,
    UPDATE_NOTIFICATION_ERRORED,
 } from '../constant/action-types';

const defaultState = {
  showGlobalLoader: false,
  notifications: [],
  error:{}
};

export function notification(state = defaultState, { type, payload }) {
  switch (type) {      
      case GET_ALL_NOTIFICATION_REQUESTED:
        // console.log("GET_ALL_NOTIFICATION_REQUESTED : ");
        return {
          ...state,
          showGlobalLoader: true,
          notifications: []
        };
    
      case GET_ALL_NOTIFICATION_SUCCESS:
        // console.log("GET_ALL_INVESTOR_SUCCESS : ", JSON.stringify(payload));
        return {
          ...state,
          showGlobalLoader: false,
          notifications : payload.items
        };
  
        case GET_ALL_NOTIFICATION_ERRORED:
        return {
          ...state,
          showGlobalLoader: false,
          error: payload
        };
        case UPDATE_NOTIFICATION_REQUESTED:
          // console.log("UPDATE_NOTIFICATION_REQUESTED : ");
          return {
            ...state,
            showGlobalLoader: true,
          };
    
        case UPDATE_NOTIFICATION_SUCCESS:
          // console.log("UPDATE_NOTIFICATION_SUCCESS : ", JSON.stringify(payload));
          return {
            ...state,
            showGlobalLoader: false,
          };
    
        case UPDATE_NOTIFICATION_ERRORED:
          return {
            ...state,
            showGlobalLoader: false,
            error: payload
          };
  
    default:
      return state;
    }
}