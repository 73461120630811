import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import configureStore from "../../../../store/configureStore";
import { timeFormat, downloadFromUrl } from "../../../common/CommonFunctions";
import { CommonFileUpload } from "./Common/AppCommonFunction";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import ImageFileViewer from "react-file-viewer-1";
import FileViewer from "react-file-viewer";

import FormControl from "@material-ui/core/FormControl";

import {
  create_update_work,
  create_update_workline,
  get_all_workline,
} from "./action-ticket";
import { showSnackbar } from "../../../../actions/action-snackbar";

import Button from "@material-ui/core/Button";
import {
  get_all_documents_by_workid,
  delete_document,
} from "../../../../actions/action-document";
import Loader from "../../../common/Loader";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import CloseIcon from "@material-ui/icons/Close";
import GetAppIcon from "@material-ui/icons/GetApp";
import VisibilityIcon from "@material-ui/icons/Visibility";
import axios from "axios";

const store = configureStore();

function TicketDetails(props) {
  const { work, usersToshow, closeWorkDash, rerender } = props;
  const projectList = store.getState().tag.primaryTags;
  const sprintList = store.getState().tag.secondaryTags;

  const [state, setState] = React.useState(work);
  const [comment, setComment] = React.useState();
  const [user, setUser] = React.useState();
  const [project, setProject] = React.useState();
  const [sprint, setSprint] = React.useState();

  const [editCommentBox, setEditCommentBox] = React.useState("");
  const [editedComment, setEditComment] = React.useState();
  const [attachment, setFile] = React.useState();
  const [commentAttachment, setCommentFile] = React.useState();

  const path = window.location.pathname.split("/");
  const ouid = path[1];

  const Icontooltip = withStyles({
    tooltip: {
      fontSize: 12,
    },
  })(Tooltip);

  useEffect(() => {
    store.dispatch(get_all_documents_by_workid(ouid, work.identifier));
    store.dispatch(get_all_workline(ouid, work.identifier, "comment"));
    setState(work);
  }, [JSON.stringify(work)]);

  const openEditComment = (index, comment) => {
    setEditCommentBox(index);
    setEditComment(comment);
  };

  const userChange = (newValue) => {
    if (newValue) {
      setUser(newValue.data.username);
      setState({ ...state, username: newValue.data.username });
    } else {
      setUser("");
      setState({ ...state, username: "" });
    }
  };

  const onInputChange = (event) => {
    if (event.target.id === "comment") {
      setComment(event.target.value);
    } else {
      if (event.target.id) {
        setState({ ...state, [event.target.id]: event.target.value });
      } else {
        setState({ ...state, [event.target.name]: event.target.value });
      }
    }
  };

  const editCommentInputChange = (event) => {
    setEditComment(event.target.value);
  };

  const updateWork = async () => {
    if (work === state) {
      console.log("No change");
    } else {
      let data = state;
      if (user) {
        data["username"] = user;
      }
      if (project) {
        data["primaryTag"] = project;
      }
      if (sprint) {
        data["secondaryTag"] = sprint;
      }
      const workid = data.identifier;
      const path = window.location.pathname.split("/");
      const ouid = path[1];
      if (ouid && workid) {
        let updateWorkDetails = { data: data };
        await store.dispatch(
          create_update_work(workid, ouid, updateWorkDetails)
        );
        store.dispatch(showSnackbar("Ticket updated successfully", "success"));

        rerender();
      }
    }
  };

  const addComment = async () => {
    console.log("Add Comment");
    if (comment) {
      let payload = {
        comment: comment,
        workId: work.identifier,
        type: "comment",
      };
      const worklineId = await store.dispatch(
        create_update_workline("", ouid, payload, "")
      );
      if (state.identifier && commentAttachment) {
        await CommonFileUpload(
          state.identifier,
          "COMMENT",
          commentAttachment,
          worklineId
        );
        console.log("CommonFileUpload", state.identifier);
      } else {
        // window.location.reload();
        setComment("");
        store.dispatch(get_all_workline(ouid, work.identifier, "comment"));
      }
    } else {
      console.log("No comment");
    }
  };

  const updateComment = async (id, oldItem) => {
    // console.log("updateComment  newValue : ", id);
    // console.log("updateComment  oldItem : ", JSON.stringify(oldItem));
    oldItem.data["comment"] = editedComment;
    // console.log("updateComment  newItem : ", JSON.stringify(oldItem));
    if (id === oldItem.data.identifier) {
      await store.dispatch(create_update_workline(id, ouid, oldItem, ""));
      // window.location.reload();
      store.dispatch(get_all_workline(ouid, work.identifier, "comment"));
    }
  };

  const onFileChange = (event) => {
    console.log("File change");
    if (event.target.id === "commentFile") {
      // console.log("File commnet-file change", event.target.files[0])
      console.log("setCommentFile");
      setCommentFile(event.target.files[0]);
    } else {
      console.log("setFile");
      setFile(event.target.files[0]);
    }
  };

  const uploadFile = async () => {
    console.log("UploadFile");
    if (state.identifier && attachment) {
      await CommonFileUpload(state.identifier, "", attachment);
      await store.dispatch(get_all_documents_by_workid(ouid, work.identifier));
      //todo - probably not good to do this way in react?
      setFile(null);
      document.getElementById("fileInput").value = "";
    }
  };

  const renderDocument = (item) => {
    switch (item.data.key.split(".").pop()) {
      case "jpg":
      case "jpeg":
      case "gif":
      case "bmp":
      case "png": {
        return (
          <ImageFileViewer
            fileType={item.data.key.split(".").pop()}
            filePath={item.SignedUrl}
          />
        );
      }
      default: {
        return (
          <FileViewer
            fileType={item.data.key.split(".").pop()}
            filePath={item.SignedUrl}
          />
        );
      }
    }
  };

  const renderDocuments = (documents) => {
    console.log("documents ", documents);

    return documents.map((item, index) => (
      <div className="ticketsDocumentItem">
        <div>
          <div>{documents.length < 50 ? renderDocument(item) : ""}</div>
          <div className="ticketsDocumentItemName">
            <a href={item.SignedUrl}>{item.data.name}</a>
          </div>
        </div>
        <div className="ticketsDocumentItemBtnWrapper">
          <Icontooltip title="View">
            <IconButton
              aria-label={item.SignedUrl}
              onClick={() => window.open(item.SignedUrl, "_blank")}
            >
              <VisibilityIcon />
            </IconButton>
          </Icontooltip>
          <Icontooltip title="Download">
            <IconButton
              aria-label="download"
              onClick={() => {
                getDownloadUrl(item.data.key, item);
              }}
            >
              <GetAppIcon />
            </IconButton>
          </Icontooltip>
          <Icontooltip title="Delete">
            <IconButton
              aria-label="delete"
              onClick={() => {
                deleteDocument(item);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Icontooltip>
        </div>
      </div>
    ));
  };

  const getDownloadUrl = async (key, documentDetails) => {
    const { apiEndpoint, credentials } = store.getState().jwt.api;

    if (apiEndpoint && credentials) {
      const { activeOuid, activeOuidChain } = store.getState().permission;
      const method = "get";
      const jwt = `${credentials.idToken.jwtToken}`;
      const url = `${apiEndpoint}/document?getDownloadSignedUrl=true&file=${key}`;
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then(async (response) => {
          console.log("signed url get response : ", JSON.stringify(response));
          console.log(
            "signed url download : ",
            JSON.stringify(response.data.getURL)
          );
          await downloadFromUrl(
            response.data.getURL,
            documentDetails.data.name
          );
        })
        .catch((err) => {
          console.log("signed url get failed : ", err);
        });
    }
  };

  const deleteDocument = async (documentDetails) => {
    const docId = documentDetails.data.identifier;
    console.log("deleteDocument", docId);
    await store.dispatch(delete_document(docId));
    await store.dispatch(get_all_documents_by_workid(ouid, work.identifier));
  };

  const renderWorkline = (worklines) => {
    return worklines.map((item, index) => (
      <div className="ticketDetailsCommentItem">
        <div className="ticketDetailsCommentHeader">
          <div className="ticketDetailsCommentMetaWrap">
            <span className="ticketDetailsCommentAvatar"></span>
            <span className="ticketDetailsCommentUser">
              {item.data.createdBy}
            </span>{" "}
            added a comment on
            <span> {timeFormat(item.data.createdDate)}</span>
          </div>
          <div className="ticketDetailsCommentActionWrap">
            <Icontooltip title="Edit">
              <IconButton
                aria-label="view"
                onClick={() => {
                  openEditComment(index, item.data.comment);
                }}
              >
                <EditIcon />
              </IconButton>
            </Icontooltip>
            <Icontooltip title="Delete">
              <IconButton aria-label="delete" onClick={() => {}} size="small">
                <DeleteIcon />
              </IconButton>
            </Icontooltip>
          </div>
        </div>
        <div>{item.data.comment}</div>
        {editCommentBox === index && (
          <div className="form-item-wrapper ticketDetailsCommentFormWrap">
            <TextField
              className="input"
              id="edditComment"
              label="Comment"
              rows={4}
              placeholder="Comment"
              multiline
              fullWidth
              value={editedComment}
              onChange={(event) => editCommentInputChange(event)}
              variant="outlined"
            />
            <Button
              onClick={() => {
                updateComment(item.data.identifier, item);
              }}
              variant="contained"
              color="primary"
            >
              Update
            </Button>
          </div>
        )}
      </div>
    ));
  };

  const projectChange = (newValue) => {
    console.log("newValue  for [project] : ", newValue);

    if (newValue) {
      setProject(newValue.data.identifier);
      setState({ ...state, primaryTag: newValue.data.identifier });
    } else {
      setProject("");
      setState({ ...state, primaryTag: "" });
    }
  };

  const sprintChange = (newValue) => {
    console.log("newValue  for [sprint] : ", newValue);

    if (newValue) {
      setSprint(newValue.data.identifier);
      setState({ ...state, secondaryTag: newValue.data.identifier });
    } else {
      setSprint("");
      setState({ ...state, secondaryTag: "" });
    }
  };

  const renderDetails = () => {
    console.log("projectList", projectList);
    console.log("sprintList", sprintList);

    const currentProject = projectList.find(
      ({ data }) => data.identifier === work.primaryTag
    );
    const currentSprint = sprintList.find(
      ({ data }) => data.identifier === work.secondaryTag
    );

    console.log("currentProject", currentProject);
    console.log("currentSprint", currentSprint);

    return (
      <>
        <div className="form-item-wrapper">
          <TextField
            id="dueDate"
            label="Due Date"
            type="date"
            className="input"
            value={state.dueDate}
            onChange={onInputChange}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </div>
        {projectList.length !== 0 ? (
          <div className="form-item-wrapper">
            <Autocomplete
              id="project"
              onChange={(event, newValue) => {
                projectChange(newValue);
              }}
              options={projectList}
              getOptionLabel={(option) =>
                option.data ? option.data.identifier : ""
              }
              defaultValue={currentProject}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} label="Project" variant="outlined" />
              )}
            />
          </div>
        ) : (
          "No Projects Found....."
        )}

        {sprintList.length !== 0 ? (
          <div className="form-item-wrapper">
            <Autocomplete
              id="sprint"
              onChange={(event, newValue) => {
                sprintChange(newValue);
              }}
              options={sprintList}
              getOptionLabel={(option) =>
                option.data ? option.data.identifier : ""
              }
              defaultValue={currentSprint}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} label="Sprint" variant="outlined" />
              )}
            />
          </div>
        ) : (
          "No Sprints Found....."
        )}
        <div className="form-item-wrapper">
          <FormControl variant="outlined">
            <InputLabel id="label">Priority</InputLabel>
            <Select
              labelId="label"
              id="priority"
              name="priority"
              value={state.priority || ""}
              onChange={onInputChange}
              label="Priority"
            >
              <MenuItem value="P1">P1</MenuItem>
              <MenuItem value="P2">P2</MenuItem>
              <MenuItem value="P3">P3</MenuItem>
              <MenuItem value="P4">P4</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="form-item-wrapper">
          <FormControl variant="outlined">
            <InputLabel id="Status-label">Status</InputLabel>
            <Select
              labelId="Status-label"
              id="status"
              name="status"
              value={state.status || ""}
              onChange={onInputChange}
              label="Status"
            >
              <MenuItem value="OPEN">Open</MenuItem>
              <MenuItem value="ASSIGNED">Assigned</MenuItem>
              <MenuItem value="INPROGRESS">InProgress</MenuItem>
              <MenuItem value="DONE">Done</MenuItem>
              <MenuItem value="CANCELLED">Cancelled</MenuItem>
            </Select>
          </FormControl>
        </div>
      </>
    );
  };

  const { showGlobalLoader, documents } = store.getState().document;
  const { showGlobalLoaderWorkline, worklines } = store.getState().work;
  const { showUploadLoader } = store.getState().document;
  const currentUserData = usersToshow.find(
    ({ data }) => data.username === work.username
  );

  return (
    <div className="TicketdetailsDrawer">
      <Button
        onClick={() => {
          closeWorkDash();
        }}
        variant="contained"
        className="detailsCloseBtn"
      >
        <CloseIcon />
      </Button>
      <div className="scrolable">
        <div className="form-item-wrapper">
          <TextField
            className="input"
            id="TicketTitle"
            value={state.title}
            onChange={onInputChange}
            fullWidth
            InputProps={{ disableUnderline: true }}
          />
        </div>
        <div className="form-item-wrapper">
          <TextField
            className="input"
            id="detail"
            label="Detail"
            rows={6}
            placeholder="Detail"
            multiline
            value={state.detail}
            onChange={onInputChange}
            variant="outlined"
            fullWidth
          />
        </div>
        <div className="ticketsDocumentWrapper">
          <h3>Documents</h3>
          {showGlobalLoader ? (
            <Loader />
          ) : documents.length !== 0 ? (
            <div className="ticketsDocumentItemWrapper">
              {renderDocuments(documents)}
            </div>
          ) : (
            "No Documents yet..."
          )}
          <div className="form-item-wrapper">
            <input type="file" onChange={onFileChange} id="fileInput" />
            <div>&nbsp;</div>
            {attachment && (
              <Button
                onClick={() => {
                  uploadFile();
                }}
                variant="contained"
                color="primary"
              >
                Upload
              </Button>
            )}
          </div>
          {showUploadLoader ? <Loader /> : ""}
        </div>
        <div className="ticketsCommentWrapper">
          <div>
            <h3>Comments</h3>
            <div>
              {showGlobalLoaderWorkline ? (
                <Loader />
              ) : worklines.length !== 0 ? (
                <div>{renderWorkline(worklines)}</div>
              ) : (
                "No Comments yet..."
              )}
            </div>
            <div className="form-item-wrapper ticketDetailsCommentFormWrap">
              <TextField
                className="input"
                id="comment"
                label="Comment"
                rows={4}
                placeholder="Comment"
                multiline
                fullWidth
                value={comment}
                onChange={(event) => onInputChange(event)}
                variant="outlined"
              />
              <Button
                onClick={() => {
                  addComment();
                }}
                variant="contained"
                color="primary"
              >
                Add
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="ticketDetailsWrapper">
        <div>{state && renderDetails()}</div>

        <div>
          {usersToshow.length !== 0 ? (
            <div className="form-item-wrapper">
              <Autocomplete
                id="combo-box-demo"
                defaultValue={currentUserData}
                onChange={(event, newValue) => {
                  userChange(newValue);
                }}
                options={usersToshow}
                getOptionLabel={(option) => option.data.username}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select User"
                    variant="outlined"
                  />
                )}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="ticketDetailsSaveBtn">
        <Button
          onClick={() => {
            updateWork();
          }}
          variant="contained"
          color="primary"
        >
          Update Ticket
        </Button>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  permission: state.permission,
  document: state.document,
});

const mapDispatchToProps = (dispatch) => ({
  delete_document: (docId) => dispatch(delete_document(docId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketDetails);
