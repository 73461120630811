import React, { Component } from 'react';
import { connect } from 'react-redux';
import configureStore from '../../../store/configureStore';
import Sidebar from '../../layout/Sidebar';
import Player from './Player';
import axios from 'axios';

const store = configureStore();

class Vod extends Component {

    constructor() {
        super();
        this.state = {
            signedUrl: '',
        };
    }

    componentDidMount() {
        this.fetchDetails();
    }

    fetchDetails() {
        const method = 'get';
        const url = 'https://d2cgzyhfc8zbbj.cloudfront.net/sign?movie=1';
        const axiosHeader = {
            headers: {
            },
        };
        axios[method](
            url,
            axiosHeader,
        )
            .then((response) => {
                console.log("Get  SignedUrl Success : ", JSON.stringify(response));
                // dispatch(showSnackbar('Get All Channel Partner Successfully', 'success'));
                this.setState({
                    signedUrl: response.data,
                });
            })
            .catch((err) => {
                console.log('Get  SignedUrl Failed');
            });
    }

    render() {
        const { permissions } = store.getState().permission;
        return (
            <div className="page">
                <Sidebar
                    auth={this.props.auth}
                    permissions={permissions}
                />
                <section className="section">
                    {this.state.signedUrl && (
                        <Player
                            urlSrc={this.state.signedUrl}
                        />
                    )}

                </section>
            </div >
        )
    }
}

const mapStateToProps = (state) => ({
    permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
    // get_user_profile_details: (username) => dispatch(get_user_profile_details(username)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Vod);