import {
  CREATE_POST_REQUESTED,
  CREATE_POST_SUCCESS,
  CREATE_POST_ERRORED,
  GET_ALL_POSTS_REQUESTED,
  GET_ALL_POSTS_SUCCESS,
  GET_ALL_POSTS_ERRORED,
  GET_POST_REQUESTED,
  GET_POST_SUCCESS,
  GET_POST_ERRORED,
} from '../constant/action-types';

const initialState = {
  showGlobalLoader: false,
  posts: [],
  post: {},
  error: {}
};

export function post(state = initialState, { type, payload }) {
  switch (type) {

    case CREATE_POST_REQUESTED:
      return {
        ...state,
      };

    case CREATE_POST_SUCCESS:
      return {
        ...state,
      };

    case CREATE_POST_ERRORED:
      return {
        ...state,
        error: payload
      };

    case GET_ALL_POSTS_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
        posts: []
      };

    case GET_ALL_POSTS_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        posts: payload.items
      };

    case GET_ALL_POSTS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload
      };

    case GET_POST_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
        post: {}
      };

    case GET_POST_SUCCESS:
      console.log("GET__POST__SUCCESS : ");
      return {
        ...state,
        showGlobalLoader: false,
        post: payload
      };

    case GET_POST_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload
      };


    default:
      return state;
  }
}
