import {
  CREATE_USER_PROFILE_REQUESTED,
  CREATE_USER_PROFILE_SUCCESS,
  CREATE_USER_PROFILE_ERRORED,
  UPDATE_USER_PROFILE_REQUESTED,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_ERRORED,
  GET_USER_PROFILE_DETAILS_REQUESTED,
  GET_USER_PROFILE_DETAILS_SUCCESS,
  GET_USER_PROFILE_DETAILS_ERRORED,
  GET_ALL_USER_PROFILES_REQUESTED,
  GET_ALL_USER_PROFILES_SUCCESS,
  GET_ALL_USER_PROFILES_ERRORED,
  DELETE_USER_PROFILE_REQUESTED,
  DELETE_USER_PROFILE_SUCCESS,
  DELETE_USER_PROFILE_ERRORED
} from '../constant/action-types';

const defaultState = {
  showGlobalLoader: false,
  userProfileDetails: {},
  userProfiles: [],
  error: {}
};

export function userProfile(state = defaultState, action) {
  switch (action.type) {

    case CREATE_USER_PROFILE_REQUESTED:
      console.log("CREATE_USER_PROFILE_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
      };

    case CREATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case CREATE_USER_PROFILE_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.error
      };

    case UPDATE_USER_PROFILE_REQUESTED:
      console.log("UPDATE_USER_PROFILE_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
      };

    case UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case UPDATE_USER_PROFILE_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.error
      };

    case GET_USER_PROFILE_DETAILS_REQUESTED:
      console.log("GET_USER_PROFILE_DETAILS_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
        userProfileDetails: {}
      };

    case GET_USER_PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        userProfileDetails: action.payload
      };

    case GET_USER_PROFILE_DETAILS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.error
      };

    case GET_ALL_USER_PROFILES_REQUESTED:
      console.log("GET_ALL_USER_PROFILES_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
        userProfiles: []
      };

    case GET_ALL_USER_PROFILES_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        userProfiles: action.payload.items
      };

    case GET_ALL_USER_PROFILES_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.error
      };


    case DELETE_USER_PROFILE_REQUESTED:
      // console.log("DELETE_USER_PROFILE_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
      };

    case DELETE_USER_PROFILE_SUCCESS:
      // console.log("DELETE_USER_PROFILE_SUCCESS : ", JSON.stringify(payload));
      return {
        ...state,
        showGlobalLoader: false,
      };

    case DELETE_USER_PROFILE_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.payload
      };


    default:
      return state;
  }
}