import React, { Component } from 'react';
import { connect } from 'react-redux';
import configureStore from '../../../store/configureStore';
import Sidebar from '../../layout/Sidebar';
import { history } from '../../../reducers';
import { get_all_works } from '../../../actions/action-work';
import CommonSnackBar from "../../common/CommonSnackBar";
import { setActiveOuid } from "../../../actions/action-permissions";
import { get_all_orguesr } from '../../../actions/action-orguser';
import WorkQueue from '../Work/WorkQueue';
import MyWorkQueue from '../Work/MyWorkQueue';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const store = configureStore();

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

class OrgWorkQueueBoard extends Component {

    constructor() {
        super();
        this.state = {
            currentUser: '',
            teamsToshow: [],
            activeTeamId: '',
            worksToshow: [],
            orgWorkStatus: "open",
            myWorkStatus: "assigned",
            myWorksToshow: [],
            usersToshow: [],
            currentWork: {},
            workDash: false,
            tabValue: 0
        };
    }

    componentDidMount() {
        if (!this.props.auth.isAuthenticated) {
            const path = window.location.pathname;
            history.push(`/login?redirect=${path}`);
            return
        }
        this.unregisterHistoryListener = history.listen((location, action) => {
            const path = window.location.pathname.split('/');
            if (path[path.length - 1] === "board") {
                this.fetchDetails();
            }
        });
        this.fetchDetails();
    }

    componentWillUnmount() {
        console.log("componentWillUnmount");
        this.unregisterHistoryListener()
        // store.dispatch(hideSnackbar());
    }


    async fetchDetails() {
        const path = window.location.pathname.split('/');
        const ouid = path[1];
        await store.dispatch(setActiveOuid(ouid));
        const { session } = store.getState().jwt.api.userSession;
        this.setState({
            currentUser: session.username,
        }, async () => {
            const { get_all_works } = this.props;
            await get_all_works(ouid, 'work', "", this.state.orgWorkStatus, "");
            this.setState({
                worksToshow: store.getState().work.works,
            });
            await get_all_works(ouid, 'work', "", this.state.myWorkStatus, this.state.currentUser);
            this.setState({
                myWorksToshow: store.getState().work.works,
            });
            const { get_all_orguesr } = this.props;
            await get_all_orguesr(ouid);
            this.setState({
                usersToshow: store.getState().orgUserProfile.orgUserProfiles,
            });
        });
    }

    openWorkDash(data) {
        // console.log("openWorkDash : ", data.row)
        this.setState({
            workDash: true,
            currentWork: data.row
        });
        // console.log("Data : ", JSON.stringify(data))
    }

    renderWork(works, activeOuid, teamId) {
        let newRows = [];
        works.map((work, index) => {
            let newRow = work.data;
            newRow['id'] = work.data.identifier;
            newRows.push(newRow);
        });
        // console.log("newRows   :::: ", newRows);
        return newRows;
    }

    async handleTeamWorkStatusChange(value) {
        // console.log("value :: ", JSON.stringify(value));
        this.setState({
            teamWorkStatus: value
        });
        const path = window.location.pathname.split('/');
        const ouid = path[1];
        const { get_all_works } = this.props;
        await get_all_works(ouid, "work", "", value, "");
        this.setState({
            worksToshow: store.getState().work.works,
        });
    }

    async handleMyWorkStatusChange(value) {
        // console.log("value :: ", JSON.stringify(value));
        this.setState({
            myWorkStatus: value
        });
        const path = window.location.pathname.split('/');
        const ouid = path[1];
        const { get_all_works } = this.props;
        await get_all_works(ouid, "work", '', value, this.state.currentUser);
        this.setState({
            myWorksToshow: store.getState().work.works,
        });
    }

    handleTabChange = (event, newValue) => {
        this.setState({
            tabValue: newValue
        });
    };

    a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    render() {
        const { permissions } = store.getState().permission;
        return (
            <div className="page">
                <Sidebar
                    auth={this.props.auth}
                    permissions={permissions}
                />
                <section className="section">
                    <div>
                        <Paper>
                            <AppBar position="static">
                                <Tabs value={this.state.tabValue} onChange={this.handleTabChange} aria-label="simple tabs example">
                                    <Tab label="My Work Queue" {...this.a11yProps(0)} />
                                    <Tab label="Organisational Work Queue" {...this.a11yProps(1)} />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={this.state.tabValue} index={0}>
                                <MyWorkQueue
                                    workContext={"org"}
                                    rows={this.renderWork(this.state.myWorksToshow)}
                                    usersToshow={this.state.usersToshow}
                                    defaultStatus={this.state.myWorkStatus}
                                    handleMyWorkStatusChange={(e) => { this.handleMyWorkStatusChange(e) }}
                                />

                            </TabPanel>
                            <TabPanel value={this.state.tabValue} index={1}>
                                <WorkQueue
                                    workContext={"org"}
                                    rows={this.renderWork(this.state.worksToshow)}
                                    usersToshow={this.state.usersToshow}
                                    defaultStatus={this.state.orgWorkStatus}
                                    handleTeamWorkStatusChange={(e) => { this.handleTeamWorkStatusChange(e) }}
                                />
                            </TabPanel>
                        </Paper>
                        <CommonSnackBar />
                    </div>
                </section>
            </div >
        )
    }
}

const mapStateToProps = (state) => ({
    permission: state.permission,
    work: state.work,
    teamUserProfile: state.teamUserProfile,
});

const mapDispatchToProps = (dispatch) => ({
    get_all_orguesr: (activeOuid) => dispatch(get_all_orguesr(activeOuid)),
    get_all_works: (ouid, type, teamId, status, username) => dispatch(get_all_works(ouid, type, teamId, status, username)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrgWorkQueueBoard);