import {
  CREATE_TAG_REQUESTED,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_ERRORED,
  GET_TAGS_REQUESTED,
  GET_PRIMARY_TAGS_REQUESTED,
  GET_SECONDARY_TAGS_REQUESTED,
  GET_TAGS_SUCCESS,
  GET_PRIMARY_TAGS_SUCCESS,
  GET_SECONDARY_TAGS_SUCCESS,
  GET_TAGS_ERRORED,
  CREATE_PRIMARY_TAG_REQUESTED,
  CREATE_SECONDARY_TAG_REQUESTED,
  CREATE_PRIMARY_TAG_SUCCESS,
  CREATE_SECONDARY_TAG_SUCCESS,
  CREATE_PRIMARY_TAG_ERRORED,
  CREATE_SECONDARY_TAG_ERRORED,
} from "../constant/action-types";
import axios from "axios";
import { showSnackbar } from "./action-snackbar";

import configureStore from "../store/configureStore";

const store = configureStore();

export const create_tag_requested = () => ({
  type: CREATE_TAG_REQUESTED,
});

export const create_primary_tag_requested = () => ({
  type: CREATE_PRIMARY_TAG_REQUESTED,
});

export const create_seconary_tag_requested = () => ({
  type: CREATE_SECONDARY_TAG_REQUESTED,
});

export const create_tag_success = () => ({
  type: CREATE_TAG_SUCCESS,
});
export const create_primary_tag_success = () => ({
  type: CREATE_PRIMARY_TAG_SUCCESS,
});

export const create_secondary_tag_success = () => ({
  type: CREATE_SECONDARY_TAG_SUCCESS,
});

export const create_tag_errored = (error) => ({
  type: CREATE_TAG_ERRORED,
  error,
});

export const create_primary_tag_errored = () => ({
  type: CREATE_PRIMARY_TAG_ERRORED,
});

export const create_secondary_tag_errored = () => ({
  type: CREATE_SECONDARY_TAG_ERRORED,
});

export const create_tag = (payload) => async (dispatch) => {
  dispatch(create_tag_requested());
  const path = window.location.pathname.split("/");
  const activeOuid = path[1];
  const { activeOuidChain } = store.getState().permission;
  console.log(`create_tag`);
  if (payload.type === "primaryTag") {
    console.log(`create_primary_tag_requested`);
    dispatch(create_primary_tag_requested());
  } else if (payload.type === "secondaryTag") {
    console.log(`create_secondary_tag_requested`);

    dispatch(create_seconary_tag_requested());
  } else {
    dispatch(create_tag_requested());
  }
  const { apiEndpoint, credentials } = store.getState().jwt.api;
  if (apiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    const url = `${apiEndpoint}/tag`;
    // console.log(`jwt : ${jwt}`);
    // console.log(`url : ${url}`);
    const method = "post";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
        "x-ouidchain": activeOuidChain,
      },
    };
    return axios[method](url, payload, axiosHeader)
      .then((response) => {
        if (payload.type === "primaryTag") {
          console.log(`create_primary_tag_requested`);
          dispatch(create_primary_tag_success());
        } else if (payload.type === "secondaryTag") {
          console.log(`create_secondary_tag_requested`);

          dispatch(create_secondary_tag_success());
        } else {
          dispatch(create_tag_success());
        }
      })
      .catch((err) => {
        console.log("Tag creation Failed");
        if (payload.type === "primaryTag") {
          console.log(`create_primary_tag_requested`);
          dispatch(create_primary_tag_errored());
        } else if (payload.type === "secondaryTag") {
          console.log(`create_secondary_tag_requested`);
          dispatch(create_secondary_tag_errored());
        } else {
          dispatch(create_tag_errored(err));
        }
      });
  }
  return null;
};

export const get_tags_requested = () => ({
  type: GET_TAGS_REQUESTED,
});

export const get_primary_tags_requested = () => ({
  type: GET_PRIMARY_TAGS_REQUESTED,
});

export const get_secondary_tags_requested = () => ({
  type: GET_SECONDARY_TAGS_REQUESTED,
});

export const get_tags_success = (payload) => ({
  type: GET_TAGS_SUCCESS,
  payload,
});
export const get_primary_tags_success = (payload) => ({
  type: GET_PRIMARY_TAGS_SUCCESS,
  payload,
});

export const get_secondary_tags_success = (payload) => ({
  type: GET_SECONDARY_TAGS_SUCCESS,
  payload,
});

export const get_tags_errored = (error) => ({
  type: GET_TAGS_ERRORED,
  error,
});

export const get_tags =
  (activeOuid, activeOuidChain, filter) => async (dispatch) => {
    console.log(`get_tags`);
    const { apiEndpoint, credentials } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${apiEndpoint}/tag`;
      if (filter) {
        if (filter.type === "primaryTag") {
          console.log(`get_primary_tags_requested`);
          dispatch(get_primary_tags_requested());
        } else if (filter.type === "secondaryTag") {
          console.log(`get_secondary_tags_requested`);

          dispatch(get_secondary_tags_requested());
        } else {
          dispatch(get_tags_requested());
        }

        url =
          `${apiEndpoint}/tag?status=` + filter.status + "&type=" + filter.type;
      }
      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get tags successful");
          if (filter.type === "primaryTag") {
            console.log(`get_primary_tags_success`);
            dispatch(get_primary_tags_success(response.data));
          } else if (filter.type === "secondaryTag") {
            console.log(`get_secondary_tags_success`);
            dispatch(get_secondary_tags_success(response.data));
          } else {
            dispatch(get_tags_success(response.data));
          }
        })

        .catch((err) => {
          console.log("Get tags failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get Tags Failed", "error"));
          }
          dispatch(get_tags_errored(err));
        });
    }
    return null;
  };
