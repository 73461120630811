import { 
    GET_MY_PROFILE_DETAILS_REQUESTED, 
    GET_MY_PROFILE_DETAILS_SUCCESS,
    GET_MY_PROFILE_DETAILS_ERRORED,
} from '../constant/action-types';
import axios from 'axios';
// import { showSnackbar } from './action-snackbar';

import configureStore from '../store/configureStore';

const store = configureStore();

export const get_my_profile_details_requested = () => ({
  type: GET_MY_PROFILE_DETAILS_REQUESTED,
});

export const get_my_profile_details_success = (payload) => ({
    type: GET_MY_PROFILE_DETAILS_SUCCESS,
    payload,
  });
  
  export const get_my_profile_details_errored = (error) => ({
    type: GET_MY_PROFILE_DETAILS_ERRORED,
    error,
  });

export const get_profile_details = (username) => async (dispatch) => {
    dispatch(get_my_profile_details_requested());
    console.log(`get_profile_details`);
    const {
      apiEndpoint,
      credentials,
    } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      const url = `${apiEndpoint}/userprofile/${username}`;
      // console.log(`jwt : ${jwt}`);
    //   console.log(`url : ${url}`);
      const method = 'get';
      const axiosHeader = {
        headers: {
          Authorization: jwt,
        },
      };
      return axios[method](
        url,
        axiosHeader,
      )
        .then((response) => {
          console.log("get my profile details success : ");
          dispatch(get_my_profile_details_success(response.data));
        })
        .catch((err) => {
          console.log('get my profile details errored');
          // console.error('ErrorLog : ', JSON.stringify(err));
          dispatch(get_my_profile_details_errored(err));
        });
    }
    return null;
  };
