import configureStore from '../../../../../store/configureStore';
import { history } from '../../../../../reducers';
import { document_upload_requested, create_document, document_upload_success } from "../../../../../actions/action-document";
import axios from 'axios';

const store = configureStore();

export function isEmptyObject(obj) {
    return !Object.keys(obj).length;
}

export async function CommonFileUpload(workId, fileCategory, attachment, worklineId) {
    console.log(`onFileUpload`);
    const path = window.location.pathname.split('/');
    const activeOuid = path[1];
    await store.dispatch(document_upload_requested());
    const {
        apiEndpoint,
        credentials,
    } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
        const fileName = attachment.name;
        const method = 'get';
        const jwt = `${credentials.idToken.jwtToken}`;
        const url = `${apiEndpoint}/document?getUploadSignedUrl=true&file=${fileName}&contentType=${attachment.type}`;
        const axiosHeader = {
            headers: {
                Authorization: jwt,
                'x-ouid': activeOuid
            },
        };
        return axios[method](
            url,
            axiosHeader,
        )
            .then(async (response) => {
                console.log("signed url get response : ", response);
                await uploadFile(attachment, fileCategory, response, workId, worklineId);
            })
            .catch((err) => {
                console.log("signed url get failed : ", err);
            });
    }
}

async function uploadFile(attachment, fileCategory, signedUrlData, workId, worklineId) {
    console.log(`uploadFile`);
    const {
        apiEndpoint,
        credentials,
    } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
        const method = 'put';
        const url = signedUrlData.data.uploadURL;
        const body = attachment;
        const axiosHeader = {
            headers: {
                "Content-type": attachment.type,
            },
        };
        return axios[method](
            url,
            body,
            axiosHeader,
        )
            .then(async (response) => {
                console.log("s3 upload response : ", response);
                var payload = {
                    "name": attachment.name,
                    "key": signedUrlData.data.Key
                }
                payload['type'] = fileCategory;
                if(workId){
                    payload['workId'] = workId;
                }
                if(worklineId){
                    payload['worklineId'] = worklineId;
                }
                await store.dispatch(create_document(payload));
                await store.dispatch(document_upload_success());
                // window.location.reload();
            })
            .catch((err) => {
                console.log("s3 upload failed : ", err);
            });
    }
}