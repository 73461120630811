import React, { Component } from 'react';
import { connect } from 'react-redux';
import UserCard from '../../common/UserCard';
import { get_all_user_profile_details } from '../../../actions/action-userProfiles';
import configureStore from '../../../store/configureStore';
import SearchField from '../../common/SearchField';
import Loader from '../../common/Loader';
import Button from '@material-ui/core/Button';
import { history } from '../../../reducers';
import Sidebar from '../../layout/Sidebar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CommonSnackBar from "../../common/CommonSnackBar";


const store = configureStore();

class Users extends Component {
  constructor() {
    super();
    this.state = {
      usersToShow: '',
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      const path = window.location.pathname;
      history.push(`/login?redirect=${path}`);
      return
    }
    this.fetchDetails();
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }

  async fetchDetails() {
    const { get_all_user_profile_details } = this.props;
    await get_all_user_profile_details();
    const { userProfiles } = store.getState().userProfile;
    this.setState({
      usersToShow: userProfiles,
    });
  }

  renderUsers(users) {
    const { activeOuid } = store.getState().permission;
    console.log("Users : ", JSON.stringify(users));
    return (
        users.map((row) => (
          <TableRow key={row.name}>
            <TableCell component="th" scope="row">
              <Button variant="text" onClick={() => { history.push(`/${activeOuid}/user/${row.data.username.replace(/ /g, '-')}`); }}>
                {row.data.name}
              </Button>
            </TableCell>
            <TableCell>{row.data.username}</TableCell>
            <TableCell>{row.data.email}</TableCell>
            <TableCell>{row.data.phone_number}</TableCell>
            <TableCell className="activeColor">{row.data.cognitoStatus}</TableCell>
            <TableCell>
              <Button variant="contained" color="primary" onClick={() => { history.push(`/${activeOuid}/edit-user/${row.data.username}`); }}>
                Edit
              </Button>
            </TableCell>

          </TableRow>
        ))
    );
  }
  // renderUsers(users) {
  //   console.log("Users : ", JSON.stringify(users));
  //   return users.map((item) => (
  //     <div>
  //       <UserCard key={item.firstname}
  //         user={item}
  //       />
  //     </div>
  //   ));
  // }

  search(keyword, users) {
    if (keyword) {
      const searchPattern = new RegExp(keyword.map((term) => `(?=.*${term})`).join(''), 'i');
      if (users) {
        this.setState({
          usersToShow: users.filter((user) => user.data.name.match(searchPattern)),
        });
      }
    } else {
      this.setState({
        usersToShow: users,
      });
    }
  }

  render() {
    const { userProfiles, showGlobalLoader } = store.getState().userProfile;
    const { activeOuid, permissions } = store.getState().permission;
    return (
      <div className="page">
        <Sidebar
          auth={this.props.auth}
          permissions={permissions}
        />
        <section className="section">

          {showGlobalLoader ?
            <Loader />
            : <div className="container">
              <div className="listHeader">
                <h1>Authenticated Users</h1>
                <SearchField onChange={(e) => this.search(e.target.value.split(' '), userProfiles)} />
                <Button onClick={() => { history.push(`/${activeOuid}/create-user`); }} variant="contained" color="primary">
                  Sign-up new user
                </Button>
              </div>
              {this.state.usersToShow &&
                <div>
                  <div className="List">
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell className="TableHeader">Name</TableCell>
                            <TableCell className="TableHeader">Username</TableCell>
                            <TableCell className="TableHeader">Email</TableCell>
                            <TableCell className="TableHeader">Mobile</TableCell>
                            <TableCell className="TableHeader">Status</TableCell>
                            <TableCell className="TableHeader"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(this.state.usersToShow.length !== 0) ? this.renderUsers(this.state.usersToShow) : 'No Users Found'}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  <CommonSnackBar />
                </div>
              }
            </div>}
        </section>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  userProfile: state.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  get_all_user_profile_details: () => dispatch(get_all_user_profile_details()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
