import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import configureStore from '../../../../store/configureStore';
import { timeFormat, downloadFromUrl } from '../../../common/CommonFunctions'
import { CommonFileUpload } from './Common/AppCommonFunction'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { create_update_work, create_update_workline, get_all_workline } from '../../../../actions/action-work';
import Button from '@material-ui/core/Button';
import { get_all_documents_by_workid } from "../../../../actions/action-document";
import Loader from '../../../common/Loader';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';


import axios from 'axios';

const store = configureStore();

function TaskDetails(props) {
    const { work, usersToshow, closeWorkDash, workType } = props;
    const [state, setState] = React.useState(work);
    const [comment, setComment] = React.useState();
    const [user, setUser] = React.useState();
    const [currentUser, setCurrentUser] = React.useState();
    const [editCommentBox, setEditCommentBox] = React.useState("");
    const [editedComment, setEditComment] = React.useState();
    const [attachment, setFile] = React.useState();
    const [commentAttachment, setCommentFile] = React.useState();

    const path = window.location.pathname.split('/');
    const ouid = path[1];

    const Icontooltip = withStyles({
        tooltip: {
            fontSize: 12,
        },
    })(Tooltip);


    useEffect(() => {
        store.dispatch(get_all_documents_by_workid(ouid, work.identifier));
        store.dispatch(get_all_workline(ouid, work.identifier, "comment"));
        setState(work);
    }, [JSON.stringify(work)]);

    const openEditComment = (index, comment) => {
        setEditCommentBox(index);
        setEditComment(comment);
    }

    const userChange = (newValue) => {
        if (newValue) {
            // newworkDetails['username'] = newValue.data.username;
            // newworkDetails['userDisplayName'] = newValue.data.name;
            setUser(newValue.data.username);
        } else {
            setUser("");
        }
    }

    const onInputChange = (event) => {
        if (event.target.id === 'comment') {
            setComment(event.target.value);
        }
        else {
            if (event.target.id) {
                setState({ ...state, [event.target.id]: event.target.value });
            } else {
                setState({ ...state, [event.target.name]: event.target.value });
            }
        }
    }

    const editCommentInputChange = (event) => {
        setEditComment(event.target.value);
    }




    const approve = async () => {
        let data = state;
        if (user) {
            data["username"] = user;
        }
        const workid = data.identifier;
        const path = window.location.pathname.split('/');
        const ouid = path[1];
        data["status"] = "CLOSED";
        data["chosenAction"] = "APPROVED";
        if (ouid && workid) {
            let updateWorkDetails = { "data": data }
            await store.dispatch(create_update_work(workid, ouid, updateWorkDetails));
            window.location.reload();
        }
    }

    const update = async () => {
        if (work === state) {
            console.log("No change");
        } else {
            let data = state;
            if (user) {
                data["username"] = user;
            }
            const workid = data.identifier;
            const path = window.location.pathname.split('/');
            const ouid = path[1];
            if (ouid && workid) {
                let updateWorkDetails = { "data": data }
                await store.dispatch(create_update_work(workid, ouid, updateWorkDetails));
                window.location.reload();
            }
        }
    }

    const reject = async () => {
        let data = state;
        if (user) {
            data["username"] = user;
        }
        const workid = data.identifier;
        const path = window.location.pathname.split('/');
        const ouid = path[1];
        data["status"] = "CLOSED";
        data["chosenAction"] = "REJECTED";
        if (ouid && workid) {
            let updateWorkDetails = { "data": data }
            await store.dispatch(create_update_work(workid, ouid, updateWorkDetails));
            window.location.reload();
        }
    }


    const renderDetails = () => {
        return (
            <>
                <div className="form-item-wrapper">
                    <TextField
                        className="input"
                        id="taskSummary"
                        label="Task Summary"
                        placeholder="Task Summary"
                        value={state.taskSummary}
                        onChange={onInputChange}
                        variant="outlined"
                    />
                </div>

                <div className="form-item-wrapper">
                    <TextField
                        id="created_Date"
                        label="Created Date"

                        className="label"
                        value={timeFormat(state.createdDate)}
                        variant="outlined"
                    />
                </div>
                <div className="form-item-wrapper">
                    <TextField
                        id="due_Date"
                        label="Due Date"

                        className="label"
                        value={state.dueDate}
                        variant="outlined"
                    />
                </div>
                <div className="form-item-wrapper">
                    <InputLabel id="Status-label">Status</InputLabel>
                    <Select labelId="Status-label" id="priority" name="status"
                        value={state.status || ''}
                        onChange={onInputChange}
                    >
                        <MenuItem value="OPEN">Open</MenuItem>
                        <MenuItem value="ASSIGNED">Assigned</MenuItem>
                    </Select>
                </div>
            </>
        )
    }

    const currentUserData = usersToshow.find(({ data }) => data.username === work.username);

    return (
        <div className="TicketdetailsDrawer">
            <Button onClick={() => { closeWorkDash() }} variant="contained" className="detailsCloseBtn">
                Close
            </Button>

            <div className="ticketDetailsWrapper">
                <div>
                    {state &&
                        renderDetails()
                    }
                </div>
                <div>
                    {usersToshow.length !== 0 ?
                        <div className="form-item-wrapper">
                            <Autocomplete
                                id="combo-box-demo"
                                defaultValue={currentUserData}
                                onChange={(event, newValue) => {
                                    userChange(newValue);
                                }}
                                options={usersToshow}
                                getOptionLabel={(option) => option.data.username}
                                style={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Select User" variant="outlined" />}
                            />
                        </div>
                        :
                        ""}
                </div>
            </div>
            <div className="ticketDetailsSaveBtn">
                <Button onClick={() => { approve() }} variant="contained" color="primary">
                    Approve
                </Button>
                <Button onClick={() => { reject() }} variant="contained" color="primary">
                    Reject
                </Button>
                <Button onClick={() => { update() }} variant="contained" className="detailsCloseBtn" color="primary">
                    Update Task Details
                </Button>
            </div>


        </div >
    );
}
const mapStateToProps = (state) => ({
    permission: state.permission,
    document: state.document,
});

const mapDispatchToProps = (dispatch) => ({
    // get_user_profile_details: (username) => dispatch(get_user_profile_details(username)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskDetails);

