import React, { Component } from "react";
import { connect } from "react-redux";
import configureStore from "../../../store/configureStore";
import { get_org_plan_details } from "../../../actions/action-orgunit";
import Loader from "../../common/Loader";
import { history } from "../../../reducers";
import Sidebar from "../../layout/Sidebar";
import { timeFormat } from "../../common/CommonFunctions";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { Tree, TreeNode } from "react-organizational-chart";
import { css } from "@emotion/css";

const store = configureStore();

class OrgPlan extends Component {
  constructor() {
    super();
    this.state = {
      orgPlanDetails: "",
      orgNodes: "",
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      const path = window.location.pathname;
      history.push(`/login?redirect=${path}`);
      return;
    }
    this.fetchDetails();
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }

  async fetchDetails() {
    const path = window.location.pathname.split("/");
    const id = path[path.length - 2];
    const { get_org_plan_details } = this.props;
    await get_org_plan_details(id);
    const { orgPlanDetails } = store.getState().orgunit;
    const orgNodes = await this.prepareOrgNodes(orgPlanDetails);
    this.setState({
      orgPlanDetails: orgPlanDetails,
      orgNodes: orgNodes,
    });
  }

  async prepareOrgNodes(orgPlanDetails) {
    return (
      <div>
        <Tree
          label={
            <div
              className={css`
                padding: 5px;
                border-radius: 8px;
                display: inline-block;
                border: 1px solid #ff0000;
              `}
            >
              {orgPlanDetails["id"]}
            </div>
          }
        >
          {this.prepareChildNodes(orgPlanDetails["subunits"])}
        </Tree>
      </div>
    );
  }
  isEmptyObject(obj) {
    return !Object.keys(obj).length;
  }
  prepareChildNodes(subunits) {
    var childNodes = [];
    for (var key in subunits) {
      if (subunits.hasOwnProperty(key) && subunits[key]["id"]) {
        if (this.isEmptyObject(subunits[key]["subunits"])) {
          childNodes.push(
            <TreeNode
              label={
                <div
                  className={css`
                    padding: 5px;
                    border-radius: 8px;
                    display: inline-block;
                    border: 1px solid #ff0000;
                  `}
                >
                  {subunits[key]["id"]}
                </div>
              }
            />
          );
        } else {
          childNodes.push(
            <TreeNode
              label={
                <div
                  className={css`
                    padding: 5px;
                    border-radius: 8px;
                    display: inline-block;
                    border: 1px solid #ff0000;
                  `}
                >
                  {subunits[key]["id"]}
                </div>
              }
            >
              {!this.isEmptyObject(subunits[key]["subunits"])
                ? this.prepareChildNodes(subunits[key]["subunits"])
                : "a"}
            </TreeNode>
          );
        }

        console.log(
          key + "subunits -> " + this.isEmptyObject(subunits[key]["subunits"])
        );
      }
    }

    console.log("childNodes -> ", childNodes);

    return childNodes;
    //return <TreeNode label={subunits["id"]}></TreeNode>;
  }

  render() {
    const { showGlobalLoader } = store.getState().orgunit;
    const { permissions } = store.getState().permission;
    return (
      <div className="page">
        <Sidebar auth={this.props.auth} permissions={permissions} />
        <section className="section">
          {showGlobalLoader ? (
            <Loader />
          ) : (
            <div className="container">
              <div className="listHeader">
                <h1>Organisational Plan</h1>
              </div>
              {this.state.orgPlanDetails ? (
                <div className="split alignStart">
                  <div>
                    <h4>JSON</h4>
                    <TextareaAutosize
                      value={JSON.stringify(this.state.orgPlanDetails, null, 2)}
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div>
                    <h4>Graphical View</h4>
                    <div
                      style={{
                        maxWidth: "500px",
                        overflow: "scroll",
                        minHeight: "400px",
                      }}
                    >
                      {this.state.orgNodes}
                    </div>
                  </div>
                </div>
              ) : (
                "No Org Plan details Found"
              )}
            </div>
          )}
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  orgunit: state.orgunit,
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  get_org_plan_details: (id) => dispatch(get_org_plan_details(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrgPlan);
