import React, { useState } from 'react';
import { connect } from 'react-redux';
import configureStore from '../../../store/configureStore';
import Loader from '../../common/Loader';
import { history } from '../../../reducers';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { DataGrid } from '@material-ui/data-grid';
import WorkDetails from './WorkDetails';
import { timeFormat } from '../../common/CommonFunctions'

const store = configureStore();

const workColumns = [
    {
        field: 'title',
        headerName: 'Title',
        width: 300,
    },
    {
        field: 'type',
        headerName: 'Type',
        width: 150,
        editable: false,
    },
    {
        field: 'workstatus',
        headerName: 'Status',
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        width: 160,
        valueGetter: (params) =>
            `${params.getValue(params.id, 'status') || ''} ${params.getValue(params.id, 'status') === "ASSIGNED" ? "To" : ""} ${params.getValue(params.id, 'username') || ''}`,
    },
    {
        field: 'created_Date',
        headerName: 'Created Date',
        type: 'number',
        width: 200,
        editable: false,
        valueGetter: (params) =>
            `${timeFormat(params.getValue(params.id, 'createdDate')) || ''}`,
    },
    {
        field: 'createdBy',
        headerName: 'Created By',
        width: 150,
        editable: false,
    },

];

function WorkQueue(props) {
    const { workContext, rows, defaultStatus, usersToshow, handleTeamWorkStatusChange, handleDetailsClick } = props;
    const { activeOuid } = store.getState().permission;
    const { showGlobalLoader } = store.getState().work
    const path = window.location.pathname.split('/');
    const teamId = path[path.length - 2];

    const [workDash, setWorkDash] = useState(false);
    const [currentWork, setCurrentWork] = useState();
    const [selectionModel, setSelectionModel] = React.useState();

    const openWorkDash = (data) => {
        console.log("openWorkDash : ", data.row)
        setWorkDash(true);
        setCurrentWork(data.row);
        setSelectionModel(data.row.id);
        return handleDetailsClick();
    }


    const closeWorkDash = () => {
        console.log("closeWorkDas")
        setWorkDash(false);
        setCurrentWork();
        return handleDetailsClick();
    }





    return (
        <div>
            <div className="listHeader">
                <div>
                    {workContext === 'team' ?
                        <h1 className="pageHeading">Team Work Queues</h1>
                        :
                        <h1 className="pageHeading">Organisational Work Queues</h1>
                    }
                    {workContext === 'team' ?
                        <Button onClick={() => { history.push(`/${activeOuid}/create-work?teamId=${teamId}`); }} variant="contained" color="primary">
                            Add Work
                        </Button>
                        :
                        <Button onClick={() => { history.push(`/${activeOuid}/create-work`); }} variant="contained" color="primary">
                            Add Work
                        </Button>
                    }

                </div>
                <FormControl variant="outlined" className="selectBox">
                    <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={defaultStatus}
                        onChange={event => handleTeamWorkStatusChange(event.target.value)}
                        label="Status"
                    >
                        <MenuItem value="open">Open</MenuItem>
                        <MenuItem value="assigned">Assigned</MenuItem>
                        <MenuItem value="inprogress">Inprogress</MenuItem>
                        <MenuItem value="completed">Completed</MenuItem>
                    </Select>
                </FormControl>
            </div>
            {showGlobalLoader ?
                <Loader />
                :
                <div className="formList">

                    {rows !== 0 ?
                        <div style={{ height: 500, width: '100%' }} >
                            <DataGrid
                                rows={rows}
                                columns={workColumns}
                                pageSize={10}
                                checkboxSelection
                                disableSelectionOnClick
                                onCellClick={(e) => { openWorkDash(e) }}
                                selectionModel={selectionModel}
                                hideFooterSelectedRowCount
                                onSelectionModelChange={(selection) => {
                                    console.log("selection  :::: ", selection);
                                    if (selection.length > !2) {
                                        let lastElement = selection.pop();
                                        setSelectionModel(lastElement);
                                    } else {
                                        let lastElement = selection.pop();
                                        setSelectionModel(lastElement);
                                    }
                                }}
                            />
                        </div>
                        :
                        "No work queues....."
                    }
                    <div>
                        {workDash &&
                            <WorkDetails
                                workContext={workContext}
                                work={currentWork}
                                usersToshow={usersToshow}
                                closeWorkDash={() => { closeWorkDash() }}
                            />
                        }

                    </div>
                </div>
            }
        </div>
    );
}

const mapStateToProps = (state) => ({
    permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkQueue);