import React, { Component } from "react";
import { connect } from "react-redux";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import "./App.css";

//Orgunit
import Orgunit from "./components/pages/Orgunit/Orgunit";
import RegisterOrgunit from "./components/pages/Orgunit/RegisterOrgunit";
import EditOrgunitDetails from "./components/pages/Orgunit/EditOrgunitDetails";
import OrgunitDetails from "./components/pages/Orgunit/OrgunitDetails";

// Users
import OrgUsers from "./components/pages/UserManagement/OrgUsers";
import OrgUserDetails from "./components/pages/UserManagement/OrgUserDetails";
import Users from "./components/pages/UserManagement/Users";
import CreateUser from "./components/pages/UserManagement/CreateUser";
import UserDetails from "./components/pages/UserManagement/UserDetails";
import EditUser from "./components/pages/UserManagement/EditUser";

// Authentication
import { Auth } from "aws-amplify";
import LogIn from "./components/auth/LogIn";
import Register from "./components/auth/Register";
import ForgotPassword from "./components/auth/ForgotPassword";
import ForgotPasswordVerification from "./components/auth/ForgotPasswordVerification";
import ChangePassword from "./components/auth/ChangePassword";
import ChangePasswordConfirm from "./components/auth/ChangePasswordConfirm";

// Home
import Home from "./components/pages/Home";

// Profile
import MyProfile from "./components/pages/MyProfile";
import Products from "./components/Products";
import ProductAdmin from "./components/ProductAdmin";
import Welcome from "./components/auth/Welcome";

import ThankYouPage from "./components/pages/ThankYouPage";
import PageNotFound from "./components/pages/PageNotFound";
import { setJwt } from "../src/actions/action-jwt";
import { setAllPermissions } from "../src/actions/action-permissions";
import { history } from "./reducers";
import jwt from "jwt-decode";
import Help from "./components/pages/Help";

import CourseDash from "./components/pages/Post/CourseDash";
import CreateCourse from "./components/pages/Post/CreateCourse";
import CourseDetails from "./components/pages/Post/CourseDetails";

import UserHome from "./components/pages/UserHome";
import Notifications from "./components/pages/Notification/Notifications";
import PermissionError from "./components/pages/PermissionError";
import HrmAppDash from "./components/pages/Apps/HrmApp/HrmAppDash";

import {
  userRoleCheck,
  permissionsCheck,
  getParentChain,
} from "../src/components/common/CommonFunctions";
import configureStore from "../src/store/configureStore";
import config from "../src/config";
import EditOrgUser from "./components/pages/UserManagement/EditOrgUser";

import {
  setActiveOuid,
  setActiveOuidChain,
} from "../src/actions/action-permissions";
import { setActiveOiudConfigs } from "../src/actions/action-config";
import CreateOrgUser from "./components/pages/UserManagement/CreateOrgUser";
import OrgPlan from "./components/pages/Orgunit/OrgPlan";
import Teams from "./components/pages/Team/Teams";
import RegisterTeam from "./components/pages/Team/RegisterTeam";
import TeamDetails from "./components/pages/Team/TeamDetails";
import EditTeamDetails from "./components/pages/Team/EditTeamDetails";
import TeamBoard from "./components/pages/Team/TeamBoard";
import CreateWork from "./components/pages/Work/CreateWork";
import CreateTeamUser from "./components/pages/UserManagement/CreateTeamUser";
import TeamUserDetails from "./components/pages/UserManagement/TeamUserDetails";
import EditTeamUser from "./components/pages/UserManagement/EditTeamUser";
import DocumentBoard from "./components/pages/Document/DocumentBoard";
import OrgWorkQueueBoard from "./components/pages/Orgunit/OrgWorkQueueBoard";
import ConfigDash from "./components/pages/Config/ConfigDash";
import SalesPro from "./components/pages/Apps/SalesPro/SalesPro";
import TicketApp from "./components/pages/Apps/TicketApp/TicketApp";
import ApprovalTasks from "./components/pages/Apps/ApprovalTasks/ApprovalTasks";

import OrgHome from "./components/pages/Orgunit/OrgHome";
import Vod from "./components/pages/Vod/Vod";

const store = configureStore();

class App extends Component {
  state = {
    isAuthenticated: false,
    isAuthenticating: true,
    user: null,
  };

  setAuthStatus = (authenticated) => {
    this.setState({ isAuthenticated: authenticated });
  };

  setUser = (user) => {
    this.setState({ user: user });
  };

  async componentDidMount() {
    // console.log("StageEnv : ", process.env);
    // console.log("StageEnvConfig : ", config);
    try {
      const session = await Auth.currentSession();
      this.setAuthStatus(true);
      // console.log("currentSession", session);
      const user = await Auth.currentAuthenticatedUser();
      // console.log("currentSession user", user);
      this.setUser(user);
      const { setJwt } = this.props;
      await setJwt(user);
      // jwt refresh intervel...........
      this.refreshJwt();
      const idToken = user.signInUserSession.idToken;
      const decodedToken = jwt(idToken.jwtToken);
      if (decodedToken.policies) {
        const policies = decodedToken.policies.split(";");
        let teamPolicies = [];
        if (decodedToken.teamPolicies !== "")
          teamPolicies = decodedToken.teamPolicies.split(";");
        let appPolicies = [];
        if (decodedToken.appPolicies !== "")
          appPolicies = decodedToken.appPolicies.split(";");
        const allPolicy = {
          policies,
          teamPolicies,
          appPolicies,
        };
        const { setAllPermissions } = this.props;
        await setAllPermissions(allPolicy);
        const userRole = await userRoleCheck(policies);
        const path = window.location.pathname.split("/");
        const urlOuid = path[path.length - (path.length - 1)];
        // console.log("urlOuid ::: ", urlOuid);
        if (!urlOuid) {
          const { userRoles } = store.getState().permission;
          // const parentChain = await getParentChain(userRoles[0].ouid);
          // console.log("parentChain ::: ", parentChain);
          await store.dispatch(setActiveOuid(userRoles[0].ouid));
          await store.dispatch(setActiveOuidChain(userRoles[0].ouid));
          await store.dispatch(setActiveOiudConfigs(userRoles[0].ouid));
        } else {
          // const parentChain = await getParentChain(urlOuid);
          // console.log("parentChain ::: ", parentChain);
          await store.dispatch(setActiveOuid(urlOuid));
          await store.dispatch(setActiveOuidChain(urlOuid));
          await store.dispatch(setActiveOiudConfigs(urlOuid));
        }
        if (this.state.redirection) {
          this.props.history.push(this.state.redirection);
        } else {
          let activeOuid = store.getState().permission.activeOuid;
          this.props.history.push(`/${activeOuid}/`);
        }
      }
    } catch (error) {
      if (error !== "No current user") {
        console.log(error);
      }
    }
    this.setState({ isAuthenticating: false });
  }

  async refreshJwt() {
    console.log("refreshJwt");
    try {
      let intervalId = setInterval(async () => {
        const session = await Auth.currentSession();
        this.setAuthStatus(true);
        // console.log("currentSession", session);
        const user = await Auth.currentAuthenticatedUser();
        // console.log("currentSession user", user);
        this.setUser(user);
        const { setJwt } = this.props;
        await setJwt(user);
      }, 60000);
      this.setState({ intervalId: intervalId });
    } catch (e) {
      console.log("setInterval error :", e);
    }
  }

  componentWillUnmount() {
    console.log("APP unmounted");
    clearInterval(this.state.intervalId);
  }

  render() {
    const authProps = {
      isAuthenticated: this.state.isAuthenticated,
      user: this.state.user,
      setAuthStatus: this.setAuthStatus,
      setUser: this.setUser,
    };
    const { userRoles } = store.getState().permission;
    return (
      !this.state.isAuthenticating && (
        <div className="App">
          <Router history={history}>
            <div>
              {/* <Sidebar auth={authProps} /> */}
              <Switch>
                <Route
                  exact
                  path="/"
                  render={(props) => <Home {...props} auth={authProps} />}
                />

                {/* Auth */}
                <Route
                  exact
                  path="/admin"
                  render={(props) => (
                    <ProductAdmin {...props} auth={authProps} />
                  )}
                />
                <Route
                  exact
                  path="/login"
                  render={(props) => <LogIn {...props} auth={authProps} />}
                />
                <Route
                  exact
                  path="/register"
                  render={(props) => <Register {...props} auth={authProps} />}
                />
                <Route
                  exact
                  path="/forgotpassword"
                  render={(props) => (
                    <ForgotPassword {...props} auth={authProps} />
                  )}
                />
                <Route
                  exact
                  path="/forgotpasswordverification"
                  render={(props) => (
                    <ForgotPasswordVerification {...props} auth={authProps} />
                  )}
                />
                <Route
                  exact
                  path="/changepassword"
                  render={(props) => (
                    <ChangePassword {...props} auth={authProps} />
                  )}
                />
                <Route
                  exact
                  path="/changepasswordconfirmation"
                  render={(props) => (
                    <ChangePasswordConfirm {...props} auth={authProps} />
                  )}
                />
                <Route
                  exact
                  path="/welcome"
                  render={(props) => <Welcome {...props} auth={authProps} />}
                />
                {/* Auth */}

                {/* Orgunit */}
                <Route
                  exact
                  path="/:id/register-orgunit"
                  render={(props) =>
                    permissionsCheck(userRoles, "CreateOrgunit", "orgunit") ? (
                      <RegisterOrgunit {...props} auth={authProps} />
                    ) : (
                      <PermissionError {...props} auth={authProps} />
                    )
                  }
                />
                <Route
                  exact
                  path="/:id/orgunits"
                  render={(props) =>
                    permissionsCheck(userRoles, "ListOrgunit", "orgunit") ? (
                      <Orgunit {...props} auth={authProps} />
                    ) : (
                      <PermissionError {...props} auth={authProps} />
                    )
                  }
                />
                <Route
                  exact
                  path="/:id/orgunit/:id"
                  render={(props) =>
                    permissionsCheck(userRoles, "ViewOrgunit", "orgunit") ? (
                      <OrgunitDetails {...props} auth={authProps} />
                    ) : (
                      <PermissionError {...props} auth={authProps} />
                    )
                  }
                />
                <Route
                  exact
                  path="/:id/edit-orgunit/:id"
                  render={(props) =>
                    permissionsCheck(userRoles, "EditOrgunit", "orgunit") ? (
                      <EditOrgunitDetails {...props} auth={authProps} />
                    ) : (
                      <PermissionError {...props} auth={authProps} />
                    )
                  }
                />
                <Route
                  exact
                  path="/:id/orgplan"
                  render={(props) =>
                    permissionsCheck(userRoles, "OrgPlan", "orgunit") ? (
                      <OrgPlan {...props} auth={authProps} />
                    ) : (
                      <PermissionError {...props} auth={authProps} />
                    )
                  }
                />
                <Route
                  exact
                  path="/:id/org-workqueue-dash"
                  render={(props) =>
                    permissionsCheck(userRoles, "OrgWorkQueue", "orgunit") ? (
                      <OrgWorkQueueBoard {...props} auth={authProps} />
                    ) : (
                      <PermissionError {...props} auth={authProps} />
                    )
                  }
                />
                <Route
                  exact
                  path="/:id"
                  render={(props) =>
                    permissionsCheck(userRoles, "OrgHome", "orgunit") ? (
                      <OrgHome {...props} auth={authProps} />
                    ) : (
                      <PermissionError {...props} auth={authProps} />
                    )
                  }
                />
                {/* orgunit */}

                {/* app */}
                <Route
                  exact
                  path="/:id/apps/salespro"
                  render={(props) =>
                    permissionsCheck(userRoles, "Application", "orgunit") ? (
                      <SalesPro {...props} auth={authProps} />
                    ) : (
                      <PermissionError {...props} auth={authProps} />
                    )
                  }
                />
                <Route
                  path="/:id/apps/ticketapp"
                  render={(props) =>
                    permissionsCheck(userRoles, "Application", "orgunit") ? (
                      <TicketApp {...props} auth={authProps} />
                    ) : (
                      <PermissionError {...props} auth={authProps} />
                    )
                  }
                />
                <Route
                  exact
                  path="/:id/course-dash"
                  render={(props) =>
                    permissionsCheck(userRoles, "CourseDash", "post") ? (
                      <CourseDash {...props} auth={authProps} />
                    ) : (
                      <PermissionError {...props} auth={authProps} />
                    )
                  }
                />
                <Route
                  exact
                  path="/:id/create-course"
                  render={(props) =>
                    permissionsCheck(userRoles, "CreateCourse", "post") ? (
                      <CreateCourse {...props} auth={authProps} />
                    ) : (
                      <PermissionError {...props} auth={authProps} />
                    )
                  }
                />
                <Route
                  exact
                  path="/:id/course/:id"
                  render={(props) =>
                    permissionsCheck(userRoles, "CourseDetails", "post") ? (
                      <CourseDetails {...props} auth={authProps} />
                    ) : (
                      <PermissionError {...props} auth={authProps} />
                    )
                  }
                />
                <Route
                  exact
                  path="/:id/apps/approvals"
                  render={(props) =>
                    permissionsCheck(userRoles, "Application", "orgunit") ? (
                      <ApprovalTasks {...props} auth={authProps} />
                    ) : (
                      <PermissionError {...props} auth={authProps} />
                    )
                  }
                />
                <Route
                  exact
                  path="/:id/hrmapp-dash"
                  render={(props) =>
                    permissionsCheck(userRoles, "Application", "orgunit") ? (
                      <HrmAppDash {...props} auth={authProps} />
                    ) : (
                      <PermissionError {...props} auth={authProps} />
                    )
                  }
                />
                {/* app */}

                {/* Team */}
                <Route
                  exact
                  path="/:id/teams"
                  render={(props) =>
                    permissionsCheck(userRoles, "ListTeam", "team") ? (
                      <Teams {...props} auth={authProps} />
                    ) : (
                      <PermissionError {...props} auth={authProps} />
                    )
                  }
                />
                <Route
                  exact
                  path="/:id/register-team"
                  render={(props) =>
                    permissionsCheck(userRoles, "ListTeam", "team") ? (
                      <RegisterTeam {...props} auth={authProps} />
                    ) : (
                      <PermissionError {...props} auth={authProps} />
                    )
                  }
                />
                <Route
                  exact
                  path="/:id/team/:id"
                  render={(props) =>
                    permissionsCheck(userRoles, "ViewTeam", "team") ? (
                      <TeamDetails {...props} auth={authProps} />
                    ) : (
                      <PermissionError {...props} auth={authProps} />
                    )
                  }
                />
                <Route
                  exact
                  path="/:id/edit-team/:id"
                  render={(props) =>
                    permissionsCheck(userRoles, "EditTeam", "team") ? (
                      <EditTeamDetails {...props} auth={authProps} />
                    ) : (
                      <PermissionError {...props} auth={authProps} />
                    )
                  }
                />
                <Route
                  exact
                  path="/:id/team/:id/board"
                  render={(props) =>
                    permissionsCheck(userRoles, "TeamBoard", "team") ? (
                      <TeamBoard {...props} auth={authProps} />
                    ) : (
                      <PermissionError {...props} auth={authProps} />
                    )
                  }
                />
                {/* Team */}

                {/* Work */}
                <Route
                  exact
                  path="/:id/create-work"
                  render={(props) =>
                    permissionsCheck(userRoles, "TeamBoard", "team") ? (
                      <CreateWork {...props} auth={authProps} />
                    ) : (
                      <PermissionError {...props} auth={authProps} />
                    )
                  }
                />
                {/* Work */}

                {/* Document */}
                <Route
                  exact
                  path="/:id/document-dash"
                  render={(props) =>
                    permissionsCheck(userRoles, "DocumentBoard", "document") ? (
                      <DocumentBoard {...props} auth={authProps} />
                    ) : (
                      <PermissionError {...props} auth={authProps} />
                    )
                  }
                />
                {/* Document */}

                {/* Config */}
                <Route
                  exact
                  path="/:id/config-dash"
                  render={(props) =>
                    permissionsCheck(userRoles, "ConfigDash", "config") ? (
                      <ConfigDash {...props} auth={authProps} />
                    ) : (
                      <PermissionError {...props} auth={authProps} />
                    )
                  }
                />
                {/* Config */}

                {/* Config */}
                <Route
                  exact
                  path="/:id/vod"
                  render={(props) =>
                    permissionsCheck(userRoles, " vod", " vod") ? (
                      <Vod {...props} auth={authProps} />
                    ) : (
                      <PermissionError {...props} auth={authProps} />
                    )
                  }
                />
                {/* Config */}

                {/* User */}
                <Route
                  exact
                  path="/:id/orgusers"
                  render={(props) =>
                    permissionsCheck(userRoles, "ListOrgUsers", "orgUser") ? (
                      <OrgUsers {...props} auth={authProps} />
                    ) : (
                      <PermissionError {...props} auth={authProps} />
                    )
                  }
                />
                <Route
                  exact
                  path="/:id/orguser/:id"
                  render={(props) =>
                    permissionsCheck(userRoles, "ViewOrgUser", "orgUser") ? (
                      <OrgUserDetails {...props} auth={authProps} />
                    ) : (
                      <PermissionError {...props} auth={authProps} />
                    )
                  }
                />
                <Route
                  exact
                  path="/:id/edit-orguser/:id"
                  render={(props) =>
                    permissionsCheck(userRoles, "EditOrgUser", "orgUser") ? (
                      <EditOrgUser {...props} auth={authProps} />
                    ) : (
                      <PermissionError {...props} auth={authProps} />
                    )
                  }
                />
                <Route
                  exact
                  path="/:id/users"
                  render={(props) =>
                    permissionsCheck(userRoles, "ListUsers", "authUser") ? (
                      <Users {...props} auth={authProps} />
                    ) : (
                      <PermissionError {...props} auth={authProps} />
                    )
                  }
                />
                <Route
                  exact
                  path="/:id/create-user"
                  render={(props) =>
                    permissionsCheck(userRoles, "CreateUser", "authUser") ? (
                      <CreateUser {...props} auth={authProps} />
                    ) : (
                      <PermissionError {...props} auth={authProps} />
                    )
                  }
                />
                <Route
                  exact
                  path="/:id/create-org-user"
                  render={(props) =>
                    permissionsCheck(userRoles, "CreateUser", "authUser") ? (
                      <CreateOrgUser {...props} auth={authProps} />
                    ) : (
                      <PermissionError {...props} auth={authProps} />
                    )
                  }
                />
                <Route
                  exact
                  path="/:id/user/:id"
                  render={(props) =>
                    permissionsCheck(userRoles, "ViewUser", "authUser") ? (
                      <UserDetails {...props} auth={authProps} />
                    ) : (
                      <PermissionError {...props} auth={authProps} />
                    )
                  }
                />
                <Route
                  exact
                  path="/:id/edit-user/:id"
                  render={(props) =>
                    permissionsCheck(userRoles, "EditUser", "authUser") ? (
                      <EditUser {...props} auth={authProps} />
                    ) : (
                      <PermissionError {...props} auth={authProps} />
                    )
                  }
                />
                <Route
                  exact
                  path="/user-home"
                  render={(props) => <UserHome {...props} auth={authProps} />}
                />
                <Route
                  exact
                  path="/:id/create-team-user/:id"
                  render={(props) =>
                    permissionsCheck(
                      userRoles,
                      "CreateTeamUser",
                      "teamUser"
                    ) ? (
                      <CreateTeamUser {...props} auth={authProps} />
                    ) : (
                      <PermissionError {...props} auth={authProps} />
                    )
                  }
                />
                <Route
                  exact
                  path="/:id/team/:id/teamuser/:id"
                  render={(props) =>
                    permissionsCheck(userRoles, "ViewTeamUser", "teamUser") ? (
                      <TeamUserDetails {...props} auth={authProps} />
                    ) : (
                      <PermissionError {...props} auth={authProps} />
                    )
                  }
                />
                <Route
                  exact
                  path="/:id/team/:id/edit-teamuser/:id"
                  render={(props) =>
                    permissionsCheck(userRoles, "ViewTeamUser", "teamUser") ? (
                      <EditTeamUser {...props} auth={authProps} />
                    ) : (
                      <PermissionError {...props} auth={authProps} />
                    )
                  }
                />
                {/* User */}

                {/* Notification */}
                <Route
                  exact
                  path="/:id/notifications"
                  render={(props) => (
                    <Notifications {...props} auth={authProps} />
                  )}
                />
                {/* Notification */}

                <Route
                  exact
                  path="/:id/my-profile"
                  render={(props) => <MyProfile {...props} auth={authProps} />}
                />
                <Route
                  exact
                  path="/help"
                  render={(props) => <Help {...props} auth={authProps} />}
                />
                <Route
                  exact
                  path="/products"
                  render={(props) => <Products {...props} auth={authProps} />}
                />
                <Route
                  exact
                  path="/thankyou"
                  render={(props) => (
                    <ThankYouPage {...props} auth={authProps} />
                  )}
                />
                <Route
                  exact
                  path="/permission-error"
                  render={(props) => (
                    <PermissionError {...props} auth={authProps} />
                  )}
                />
                {/* <Route exact path="/404" render={(props) => <PageNotFound />} />
              <Redirect from="*" to={"/404"} /> */}
              </Switch>
            </div>
          </Router>
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  setJwt: (user) => dispatch(setJwt(user)),
  setAllPermissions: (policies) => dispatch(setAllPermissions(policies)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
