import {
    GET_ALL_TEAMS_REQUESTED,
    GET_ALL_TEAMS_SUCCESS,
    GET_ALL_TEAMS_ERRORED,
    REGISTER_TEAM_REQUESTED,
    REGISTER_TEAM_SUCCESS,
    REGISTER_TEAM_ERRORED,
    GET_TEAM_DETAILS_REQUESTED,
    GET_TEAM_DETAILS_SUCCESS,
    GET_TEAM_DETAILS_ERRORED,
    UPDATE_TEAM_DETAILS_REQUESTED,
    UPDATE_TEAM_DETAILS_SUCCESS,
    UPDATE_TEAM_DETAILS_ERRORED,
} from '../constant/action-types';
import axios from 'axios';
import { showSnackbar } from './action-snackbar';

import configureStore from '../store/configureStore';

const store = configureStore();


export const get_all_teams_requested = () => ({
    type: GET_ALL_TEAMS_REQUESTED,
});

export const get_all_teams_success = (payload) => ({
    type: GET_ALL_TEAMS_SUCCESS,
    payload
});

export const get_all_teams_errored = (error) => ({
    type: GET_ALL_TEAMS_ERRORED,
    error,
});


export const get_all_teams = (activeOuid) => async (dispatch) => {
    dispatch(get_all_teams_requested());
    console.log(`get_all_teams`);
    const {
        apiEndpoint,
        credentials,
    } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
        const jwt = `${credentials.idToken.jwtToken}`;
        let url;
        if (activeOuid) {
            url = `${apiEndpoint}/team?parentOuId=${activeOuid}`;
        } else {
            url = `${apiEndpoint}/orgunit`;
        }
        // console.log(`jwt : ${jwt}`);
        // console.log(`url : ${url}`);
        const method = 'get';
        const axiosHeader = {
            headers: {
                Authorization: jwt,
                'x-ouid': activeOuid
            },
        };
        return axios[method](
            url,
            axiosHeader,
        )
            .then((response) => {
                console.log("Get All Teams Success : ");
                // dispatch(showSnackbar('Get All Channel Partner Successfully', 'success'));
                dispatch(get_all_teams_success(response.data));
            })
            .catch((err) => {
                console.log('Get All Teams Failed');
                // console.error('ErrorLog : ', JSON.stringify(err));
                if (err.response) {
                    var data = err.response.data;
                    dispatch(showSnackbar(data.error, 'error'));
                } else {
                    dispatch(showSnackbar('Get All Teams Failed', 'error'));
                }
                dispatch(get_all_teams_errored(err));
            });
    }
    return null;
};


export const register_team_requested = () => ({
    type: REGISTER_TEAM_REQUESTED,
});

export const register_team_success = () => ({
    type: REGISTER_TEAM_SUCCESS,
});

export const register_team_errored = (error) => ({
    type: REGISTER_TEAM_ERRORED,
    error,
});

export const register_team = (payload, activeOuid, activeOuidChain) => async (dispatch) => {
    dispatch(register_team_requested());
    console.log(`register_team`);
    const {
        apiEndpoint,
        credentials,
    } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
        const jwt = `${credentials.idToken.jwtToken}`;
        const url = `${apiEndpoint}/team`;
        const method = 'post';
        const axiosHeader = {
            headers: {
                Authorization: jwt,
                'x-ouid': activeOuid,
                'x-ouidchain': activeOuidChain,
            },
        };
        return axios[method](
            url,
            payload,
            axiosHeader,
        )
            .then((response) => {
                console.log('Team Registration success');
                dispatch(showSnackbar('Team Registerd Successfully', 'success'));
                dispatch(register_team_success());
            })
            .catch((err) => {
                console.log('Team Registration Failed');
                // console.error('ErrorLog : ', JSON.stringify(err));
                dispatch(showSnackbar('Team Registration Failed', 'error'));
                dispatch(register_team_errored(err));
            });
    }
    return null;
};



export const get_team_details_requested = () => ({
    type: GET_TEAM_DETAILS_REQUESTED,
});

export const get_team_details_success = (payload) => ({
    type: GET_TEAM_DETAILS_SUCCESS,
    payload
});

export const get_team_details_errored = (error) => ({
    type: GET_TEAM_DETAILS_ERRORED,
    error,
});


export const get_team_details = (id, activeOuid, activeOuidChain) => async (dispatch) => {
    dispatch(get_team_details_requested());
    console.log(`get_team_details`);
    const {
        apiEndpoint,
        credentials,
    } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
        const jwt = `${credentials.idToken.jwtToken}`;
        const url = `${apiEndpoint}/team/${id}`;
        // console.log(`jwt : ${jwt}`);
        // console.log(`url : ${url}`);
        const method = 'get';
        const axiosHeader = {
            headers: {
                Authorization: jwt,
                'x-ouid': activeOuid,
                'x-ouidchain': activeOuidChain,
            },
        };
        return axios[method](
            url,
            axiosHeader,
        )
            .then((response) => {
                console.log("Get Team Details Success : ");
                // dispatch(showSnackbar('Get All Channel Partner Successfully', 'success'));
                dispatch(get_team_details_success(response.data));
            })
            .catch((err) => {
                console.log('Get Team Details  Failed');
                // console.error('ErrorLog : ', JSON.stringify(err));
                if (err.response) {
                    var data = err.response.data;
                    dispatch(showSnackbar(data.error, 'error'));
                } else {
                    dispatch(showSnackbar('Get Team Details Failed', 'error'));
                }
                dispatch(get_team_details_errored(err));
            });
    }
    return null;
};



export const update_team_requested = () => ({
    type: UPDATE_TEAM_DETAILS_REQUESTED,
});

export const update_team_success = (payload) => ({
    type: UPDATE_TEAM_DETAILS_SUCCESS,
    payload
});

export const update_team_errored = (error) => ({
    type: UPDATE_TEAM_DETAILS_ERRORED,
    error,
});




export const update_team = (payload, teamid, activeOuid) => async (dispatch) => {
    dispatch(update_team_requested());
    console.log(`update_team`);
    const {
        apiEndpoint,
        credentials
    } = store.getState().jwt.api;
    const { activeOuidChain } = store.getState().permission;
    if (apiEndpoint && credentials) {
        const jwt = `${credentials.idToken.jwtToken}`;
        const url = `${apiEndpoint}/team/${teamid}`;
        // console.log(`jwt : ${jwt}`);
        // console.log(`url : ${url}`);
        const method = 'put';
        const axiosHeader = {
            headers: {
                Authorization: jwt,
                'x-ouid': activeOuid,
                'x-ouidchain': activeOuidChain,
            },
        };
        return axios[method](
            url,
            payload,
            axiosHeader,
        )
            .then((response) => {
                console.log('Team Updation success');
                dispatch(showSnackbar('Team Updated Successfully', 'success'));
                dispatch(update_team_success());
            })
            .catch((err) => {
                console.log('Team Updation Failed');
                // console.error('ErrorLog : ', JSON.stringify(err));
                dispatch(showSnackbar('Team Updation Failed', 'error'));
                dispatch(update_team_errored(err));
            });
    }
    return null;
};

