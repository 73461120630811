import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import configureStore from "../../../../store/configureStore";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import SearchField from "../../../common/SearchField";

import { get_tags, create_tag } from "../../../../actions/action-tag";

import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { history } from "../../../../reducers";
import { StepButton } from "@material-ui/core";

const store = configureStore();

function renderSprints(sprints, rerender) {
  console.log("sprints  : ", JSON.stringify(sprints));
  return sprints.map((row) => (
    <TableRow key={row.data.identifier}>
      <TableCell
        onClick={() => {
          var pathname = history.location.pathname;
          var search = history.location.search;
          console.log("search", search);

          console.log("pathname", pathname);
          console.log("history.location", history.location);

          const searchParams = new URLSearchParams(search);

          searchParams.set("sprint", row.data.identifier);
          searchParams.set("allStatus", "open");
          searchParams.set("myStatus", "assigned");
          searchParams.delete("prj");
          const searchParamString = searchParams.toString();
          console.log(searchParamString);
          console.log(pathname + "?" + searchParamString);
          history.push(pathname + "?" + searchParamString);
          rerender();
        }}
      >
        {row.data.identifier}
      </TableCell>
    </TableRow>
  ));
}

function SprintListCard(props) {
  const { activeOuid, activeOuidChain } = store.getState().permission;
  const { rerender } = props;
  const [state, setState] = React.useState({
    sprintDetails: {},
    newsprint: "",
    sprintsToShow: [],
  });
  const [sprints, setSprintsToShow] = React.useState({
    sprintsToShow: [],
  });
  const [visible, setVisible] = React.useState(false);

  useEffect(() => {
    const getSprints = async (activeOuid, activeOuidChain) => {
      let filter = { type: "secondaryTag", status: "active" };
      await store.dispatch(get_tags(activeOuid, activeOuidChain, filter));
      setSprintsToShow({
        sprintsToShow: store.getState().tag.secondaryTags,
      });
    };
    getSprints(activeOuid, activeOuidChain);
    console.log("sprints fetched", store.getState().tag.secondaryTags);
  }, [activeOuid, activeOuidChain]);

  const search = async (keyword, sprints) => {
    console.log("in search sprints", keyword);
    console.log("in search sprints", sprints);

    if (keyword) {
      const searchPattern = new RegExp(
        keyword.map((term) => `(?=.*${term})`).join(""),
        "i"
      );
      if (sprints) {
        setSprintsToShow({
          sprintsToShow: sprints.filter((sprint) =>
            sprint.data.identifier
              ? sprint.data.identifier.match(searchPattern)
              : ""
          ),
        });
      }
    } else {
      setSprintsToShow({
        sprintsToShow: sprints,
      });
    }
  };

  const handleCreateSprint = async (event) => {
    console.log("New sprint", state.newsprint);
    let payload = {
      identifier: state.newsprint,
      type: "secondaryTag",
      status: "active",
    };
    await store.dispatch(create_tag(payload));
    const newsprinttDetails = state.sprintDetails;
    setState({
      sprintDetails: newsprinttDetails,
      newsprint: "",
    });
    let filter = { type: "secondaryTag", status: "active" };
    await store.dispatch(get_tags(activeOuid, activeOuidChain, filter));
    setSprintsToShow({
      sprintsToShow: store.getState().tag.secondaryTags,
    });
    setVisible(!visible);
  };

  const onNewSprintChange = (event) => {
    console.log("onNewSprintChange");
    const newsprintDetails = state.sprintDetails;
    if (event.target.id) {
      newsprintDetails[event.target.id] = event.target.value;
    } else {
      newsprintDetails[event.target.name] = event.target.value;
    }
    setState({
      sprintDetails: newsprintDetails,
      newsprint: event.target.value,
    });
  };

  return (
    <Paper className="TicketTablePaper">
      <div className="ticketTagHeadingWrap">
        <h3>Sprints</h3>
        <div>
          <IconButton
            color="primary"
            aria-label="add to shopping cart"
            onClick={() => setVisible(!visible)}
          >
            <AddCircleIcon />
          </IconButton>
        </div>
      </div>
      {visible && (
        <div className="ticketCreateProjectForm">
          <TextField
            id="filled-basic"
            label="Enter Sprint Name"
            value={state.sprintDetails ? state.sprintDetails.title : ""}
            onChange={onNewSprintChange}
            variant="outlined"
            size="small"
            fullWidth
          />
          <Button
            onClick={() => handleCreateSprint()}
            variant="contained"
            size="small"
            color="primary"
          >
            Create
          </Button>
        </div>
      )}

      <div className="ticketProjectSearch">
        <SearchField
          onChange={(e) =>
            search(
              e.target.value.split(" "),
              store.getState().tag.secondaryTags
            )
          }
        />
      </div>
      {sprints.sprintsToShow && (
        <div>
          <div className="List">
            <TableContainer>
              <Table aria-label="simple table">
                <TableBody>
                  {sprints.sprintsToShow !== 0
                    ? renderSprints(sprints.sprintsToShow, rerender)
                    : "No Sprints Found"}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      )}
    </Paper>
  );
}

const mapStateToProps = (state) => ({
  permission: state.permission,
  tag: state.tag,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SprintListCard);
