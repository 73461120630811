import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get_team_user_details, update_team_user_profile } from '../../../actions/action-teamuser';
import CommonSnackBar from "../../common/CommonSnackBar";
import { history } from '../../../reducers';
import Sidebar from '../../layout/Sidebar';
import configureStore from '../../../store/configureStore';
import FormErrors from "../../FormErrors";
import Input from '@material-ui/core/Input';
import FormButton from '../../common/FormButton';
import Loader from '../../common/Loader';
import PolicyCard from '../../common/PolicyCard';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { showSnackbar, hideSnackbar } from '../../../actions/action-snackbar';
import { setActiveOuid } from "../../../actions/action-permissions";

const store = configureStore();


class EditTeamUser extends Component {

    constructor() {
        super();
        this.state = {
            userDetails: {},
        };
    }

    componentDidMount() {
        if (!this.props.auth.isAuthenticated) {
            const path = window.location.pathname;
            history.push(`/login?redirect=${path}`);
            return
        }
            this.fetchDetails();
    }

    async fetchDetails() {
        const path = window.location.pathname.split('/');
        const username = path[path.length - 1];
        const teamId = path[path.length - 3];
        const activeOuid = path[1];
        console.log("fetchDetails : ",activeOuid);
        const { get_team_user_details } = this.props;
        await get_team_user_details(username, activeOuid, teamId);
        const { teamUserDetails } = store.getState().teamUserProfile;
        this.setState({
            userDetails: teamUserDetails,
            roles: teamUserDetails.data.roles,
        });
    }

    clearErrorState = () => {
        this.setState({
            errors: {}
        });
    }


    handleSubmit = async event => {
        const path = window.location.pathname.split('/');
        const username = path[path.length - 1];
        const { update_team_user_profile } = this.props;
        let userDetails = this.state.userDetails;
        userDetails['policies'] = this.state.policies;
        const teamId = path[path.length - 3];
        const activeOuid = path[1];
        await update_team_user_profile(this.state.userDetails, username, activeOuid, teamId);
        this.props.history.push(`/${activeOuid}/team/${teamId}/board`);
    }

    onInputChange = event => {
        const newUserDetails = this.state.userDetails;
        newUserDetails.data[event.target.id] = event.target.value;
        this.setState({
            userDetails: newUserDetails
        });
    }

    deletePolicy = async index => {
        console.log("deletePolicy : ", index);
        let roles = this.state.roles;
        roles.splice(index, 1);
        this.setState({
            roles: roles
        });
    }

    roleChange = event => {
        var newRoles = this.state.roles;
        if (newRoles.includes(event.target.value)) {
            store.dispatch(showSnackbar('Role Already Added', 'error'));
        } else {
            newRoles.push(event.target.value);
            this.setState({
                roles: newRoles
            });
        }
    }

    render() {
        const { showGlobalLoader } = store.getState().teamUserProfile;
        const { permissions } = store.getState().permission;
        return (
            <div className="page">
                <Sidebar
                    auth={this.props.auth}
                    permissions={permissions}
                />
                <section className="section">
                    {showGlobalLoader ?
                        <Loader />
                        :
                        <div className="container">
                            {this.state.userDetails.data && (
                                <div className="formContainer">
                                    <FormErrors formerrors={this.state.errors} />
                                    <form>
                                        <h1 className="formName">Edit Team User Details</h1>
                                        <div className="form-item-wrapper">
                                            <Input
                                                className="input"
                                                id="name"
                                                placeholder="Full Name"
                                                value={this.state.userDetails.data.name}
                                                onChange={this.onInputChange}
                                            />
                                        </div>
                                        <div className="form-item-wrapper">
                                            <Input
                                                className="input"
                                                id="username"
                                                placeholder="User Name"
                                                value={this.state.userDetails.data.username}
                                                onChange={this.onInputChange}
                                                readOnly
                                            />
                                        </div>
                                        <div className="form-item-wrapper">
                                            <Input
                                                className="input"
                                                placeholder="Email"
                                                id="email"
                                                value={this.state.userDetails.data.email}
                                                onChange={this.onInputChange}
                                                readOnly
                                            />
                                        </div>
                                        <div className="form-item-wrapper">
                                            <Input
                                                className="input"
                                                id="phone_number"
                                                placeholder="Phone Number"
                                                value={this.state.userDetails.data.phone_number}
                                                onChange={this.onInputChange}
                                                readOnly
                                            />
                                        </div>
                                        {this.state.roles && (
                                            <div>
                                                {this.state.roles.map((item, index) => (
                                                    <PolicyCard key={index}
                                                        policy={item}
                                                        index={index}
                                                        deletePolicy={(index) => this.deletePolicy(index)}
                                                        editable
                                                    />
                                                ))
                                                }
                                            </div>
                                        )}
                                        <div>
                                            <FormControl style={{ minWidth: 120 }}>
                                                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={this.state.orgUnitRole}
                                                    onChange={this.roleChange}
                                                >
                                                    <MenuItem value={"lead"}>Lead</MenuItem>
                                                    <MenuItem value={"member"}>Member</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </form>
                                    <div className="field">
                                        <p className="control">
                                            <FormButton
                                                onClick={() => this.handleSubmit()}
                                                text="Update"
                                            />
                                        </p>
                                    </div>
                                    <CommonSnackBar />
                                </div>
                            )}

                        </div>
                    }
                </section>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    teamUserProfile: state.teamUserProfile,
    permission: state.permission,
});


const mapDispatchToProps = (dispatch) => ({
    get_team_user_details: (username, activeOuid, teamId) => dispatch(get_team_user_details(username, activeOuid, teamId)),
    update_team_user_profile: (payload, username, activeOuid, teamId) => dispatch(update_team_user_profile(payload, username, activeOuid, teamId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditTeamUser);


